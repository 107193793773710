import { useEffect, useState } from 'react'
import LogoBanner from './LogoBanner/LogoBanner'
import ProductShowCase from './ProductShowCase/ProductShowCase'
import { VistaLogoHeader } from '../VistaLogoHeader/VistaLogoHeader'
import WebsiteBanner from './WebsiteBanner/WebsiteBanner'
import { useEventTracking } from '../../contexts/EventTrackingContext'
import { getImageDimensions } from '../../utilities/image'
import { createLogoVariantUrlGenerator } from '../../utilities/createLogoVariantUrlGenerator'
import Loader from '../Loader/Loader'

interface ShareablePageProps {
  logoId: string
  logoVariantSignedToken: string
  backgroundColor: string
}

const ShareablePage = ({ logoId, logoVariantSignedToken, backgroundColor }: ShareablePageProps) => {
  const [logoDimensions, setLogoDimensions] = useState<{ height: number; width: number } | null>(null)
  const logoUrlGenerator = createLogoVariantUrlGenerator(logoId, logoVariantSignedToken)
  const { trackPage } = useEventTracking()

  useEffect(() => {
    trackPage()
  }, [])

  const logoUrl = logoUrlGenerator({
    backgroundMode: 'TRANSPARENT',
    imageType: 'PNG',
    width: 2000,
  })

  const logoUrlForWebsiteBanner = logoUrlGenerator({
    backgroundMode: 'TRANSPARENT',
    imageType: 'PNG',
    width: 500,
    height: 500,
  })

  useEffect(() => {
    getImageDimensions(logoUrl).then(setLogoDimensions)
  }, [logoId, logoUrl])

  return (
    <>
      <VistaLogoHeader logoRedirectionUrl={'/'} />
      <LogoBanner bgColor={backgroundColor} logoUrl={logoUrl} />
      {!logoDimensions && <Loader />}
      {logoDimensions && (
        <>
          <ProductShowCase
            firstProductMpvId="standardBusinessCards"
            secondProductMpvId="premiumBallpointPens"
            backgroundColor={backgroundColor}
            logo={{
              url: logoUrl,
              width: logoDimensions.width,
              height: logoDimensions.height,
            }}
          />
          <WebsiteBanner bgColor={backgroundColor} logoUrl={logoUrlForWebsiteBanner} />
          <ProductShowCase
            firstProductMpvId="mensPremiumShortSleeveTShirts"
            secondProductMpvId="personalizedMugs"
            backgroundColor={backgroundColor}
            logo={{
              url: logoUrl,
              width: logoDimensions.width,
              height: logoDimensions.height,
            }}
          />
        </>
      )}
    </>
  )
}

export default ShareablePage
