import { CimpressDocument, DesignSurface } from '@mcp-artwork/cimdoc-types-v2'
import { GenerateDesignProduct } from '../../api/smartDesignEngine'
import { v4 as uuidv4 } from 'uuid'
import { generateMainPanel } from './generateMainPanel'
import { generateSecondaryPanel } from './generateSecondaryPanel'
import { generatePpagPanel } from './generatePpagPanel'
import { ImageWithDimensions } from '../image'
import { PanelContract, ProductsAndTargetSpecificationsResponse } from './targetSpecifications/types'
import { getTargetSpecification } from './targetSpecifications/client'
import { LogoTextColorPaletteType } from '../../types/colors'
import { toCheckIsTextReadable } from '../colors'
import { isPPAGProduct } from './utils'

export async function generateDesignDocumentForProduct(
  product: GenerateDesignProduct,
  culture: string,
  logo: ImageWithDimensions,
  backgroundColor: string,
  logoTextColorPaletteFromSignature: LogoTextColorPaletteType = {},
): Promise<CimpressDocument> {
  let specifications: ProductsAndTargetSpecificationsResponse = await getTargetSpecification(
    product.productId,
    product.productVersion,
    product.productOptions,
    culture,
  )

  if (!specifications.data.targetSpecifications.panels || specifications.data.targetSpecifications.panels.length === 0) {
    throw new Error('Missing panel data, cannot generate design for ' + product.mpvId)
  }

  return createCimDoc(
    specifications.data.targetSpecifications.panels,
    specifications.data.targetSpecifications.projectionId || '',
    product,
    logo,
    backgroundColor,
    logoTextColorPaletteFromSignature,
  )
}

function createCimDoc(
  panels: PanelContract[],
  projectionId: string,
  product: GenerateDesignProduct,
  logo: ImageWithDimensions,
  backgroundColor: string,
  logoTextColorPaletteFromSignature: LogoTextColorPaletteType,
): CimpressDocument {
  const mainPanelId = uuidv4()

  function generatePanel(panel: PanelContract, index: number): DesignSurface {
    if (index === 0) {
      const usePPAGStyle = isPPAGProduct(product.mpvId)
      const toUseTransparentBackground =
        logoTextColorPaletteFromSignature.primary && usePPAGStyle
          ? toCheckIsTextReadable(product.productOptions, logoTextColorPaletteFromSignature)
          : false
      if (usePPAGStyle) {
        return generatePpagPanel(panel, logo, toUseTransparentBackground ? 'transparent' : backgroundColor, mainPanelId)
      } else {
        return generateMainPanel(panel, logo, backgroundColor, mainPanelId)
      }
    } else {
      return generateSecondaryPanel(panel)
    }
  }

  return {
    document: {
      panels: panels.map(generatePanel),
    },
    version: '2.0',
    fontRepositoryUrl: 'https://fonts.documents.cimpress.io/v1/repositories/0a3b1fba-8fa6-43e1-8c3e-a018a5eb8150/published',
    projectionId: projectionId,
    metadata: {
      ddifVersion: '2.0',
      documentSources: {
        panels: [
          {
            id: `${mainPanelId}`,
            source: 'LOGO2LIFE',
            data: '',
          },
        ],
      },
    },
  }
}
