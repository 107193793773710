// Tracking values we inherited from Logomaker
export const PAGE_NAME = {
  DESIGN_REVIEW: 'DESIGN REVIEW',
  SHAREABLE_LOGO: 'SHAREABLE LOGO',
  BRAND_SHOP: 'Brand Shop',
}

const PAGE_SECTION = {
  DESIGN_SERVICES: 'Design Services',
  BRAND_SHOP: 'My Account',
}

const PAGE_STAGE = {
  LOGOMAKER: 'Logomaker',
  BRAND_SHOP: 'My Account',
}

const ROUTE = 'logomaker'
const NAME = `Logo Maker`

// Logomaker is also a product itself
const LOGOMAKER_CORE_PRODUCT_ID = 'PRD-WRFGWR7PP'
const LOGOMAKER_CORE_PRODUCT_VERSION = 0
const LOGOMAKER_MPV_ID = 'logoMaker'

export type PageContext = {
  pageUrl: string
  locale: string
  source: string
  utmSource: string
  utmId: string
  pageName: string
  isBrandShopRoute: boolean
}

type AnalyticsProperties = {
  utmSource: string
  utmId: string
  pageSection: string
  pageStage: string
  pageName: string
  host: string
}

const getPageProperties = (context: PageContext): AnalyticsProperties => {
  return {
    host: context.source,
    utmSource: context.utmSource,
    utmId: context.utmId,
    pageSection: context.isBrandShopRoute ? PAGE_SECTION.BRAND_SHOP : PAGE_SECTION.DESIGN_SERVICES,
    pageStage: context.isBrandShopRoute ? PAGE_STAGE.BRAND_SHOP : PAGE_STAGE.LOGOMAKER,
    pageName: context.isBrandShopRoute
      ? `${PAGE_SECTION.BRAND_SHOP}:${context.pageName}`
      : `${context.pageName}:${PAGE_SECTION.DESIGN_SERVICES}`,
  }
}

export type ProductSpecificEventProps = {
  productName: string
  productVersion: string
  prd: string
  mpvId: string
}

const getProductProperties = (product?: ProductSpecificEventProps) => {
  if (product) {
    return {
      product_id: product.mpvId,
      name: product.productName,
      core_product_id: product.prd,
      core_product_version: product.productVersion,
    }
  }

  return {
    product_id: LOGOMAKER_MPV_ID,
    route: ROUTE,
    name: NAME,
    core_product_id: LOGOMAKER_CORE_PRODUCT_ID,
    core_product_version: LOGOMAKER_CORE_PRODUCT_VERSION,
  }
}

export type PageViewedProps = {
  brandType?: string
}

export const getPageEvent = (props: PageViewedProps, pageContext: PageContext) => {
  const langAndLocale = pageContext.locale.split('-')
  return {
    pageName: '', // maintaining behaviour from Logomaker - looking at various vista pages this value is often empty
    payload: {
      language: langAndLocale[0],
      locale: langAndLocale[1],
      tenant: 'vistaprint',
      ...(pageContext.isBrandShopRoute && { pageDept: props.brandType }),
      ...getPageProperties(pageContext),
    },
  }
}

export type ButtonClickedProps = {
  eventDetail: string
  variant?: string
  product?: ProductSpecificEventProps
}

export const getButtonClickedEvent = ({ eventDetail, variant, product }: ButtonClickedProps, pageContext: PageContext) => {
  return {
    eventName: 'Button Clicked',
    payload: {
      label: eventDetail,
      category: 'Review',
      eventDetail: eventDetail,
      ...(variant && { variant: variant }),
      ...getProductProperties(product),
      ...getPageProperties(pageContext),
    },
  }
}

export type NavigationClickedProps = {
  label: string
  navigationDetail: string
  eventDetail?: string
}

export const getNavigationClickedEvent = ({ label, navigationDetail, eventDetail }: NavigationClickedProps, pageContext: PageContext) => {
  return {
    eventName: 'Navigation Clicked',
    payload: {
      category: 'Review',
      label: label,
      eventDetail: eventDetail,
      navigationDetail: navigationDetail,
      ...getProductProperties(),
      ...getPageProperties(pageContext),
    },
  }
}

export type ProductViewedProps = {
  logoId: string
  product?: ProductSpecificEventProps
}

export const getProductViewedEvent = ({ logoId, product }: ProductViewedProps, pageContext: PageContext) => {
  return {
    eventName: 'Product Viewed',
    payload: {
      label: 'Review page view',
      variant: logoId ? `logoId:${logoId}` : '',
      ...getProductProperties(product),
      ...getPageProperties(pageContext),
    },
  }
}

export type TabSelectedProps = {
  tabName: string
}

export const getTabSelectedEvent = ({ tabName }: TabSelectedProps, pageContext: PageContext) => {
  return {
    eventName: 'Tab Selected',
    payload: {
      category: 'Digital Marketing',
      label: tabName,
      pageDept: 'Digital Marketing - LogoMaker',
      ...getProductProperties(),
      ...getPageProperties(pageContext),
    },
  }
}

export type LogomakerCrosssellComponentProps = {
  productLoaded: string
}

export const getLogomakerCrosssellComponentEvent = ({ productLoaded }: LogomakerCrosssellComponentProps, pageContext: PageContext) => {
  return {
    eventName: 'Logomaker crosssell component',
    payload: {
      label: 'Download Page Recommendation:' + productLoaded,
      ...getProductProperties(),
      ...getPageProperties(pageContext),
    },
  }
}

export type DownloadPageLogoDetailsProps = {
  companyNameLength: string
  taglineLength: string
  companyNameFont: string
  taglineFont: string
  iconType: string
  colorType: string
  flipIcon: string
  layout: string
  container: string
  logoId: string
  logoSelectionFrom?: string
  totalFavLogos?: number
  industry: string
  industryKeywords: string
  iconId: string
  sourceName: string
  totalGradientInFav?: string
  isPremium: boolean
  galleryLogoIndex?: number
  isEngagedWithFontFilter?: boolean
  royaltyTier?: string
  iconCreatedBy?: string
  iconUrl?: string
}

export const getDownloadPageLogoDetailsEvent = (props: DownloadPageLogoDetailsProps, pageContext: PageContext) => {
  return {
    eventName: 'DownloadPage Logo Details',
    payload: {
      label: 'DownloadPage:Logo Details',
      ...getProductProperties(),
      ...getPageProperties(pageContext),
      companyNameLength: props.companyNameLength,
      taglineLength: props.taglineLength,
      companyNameFont: props.companyNameFont,
      taglineFont: props.taglineFont,
      iconType: props.iconType,
      flipIcon: props.flipIcon,
      colorType: props.colorType,
      layout: props.layout,
      container: props.container,
      logoId: props.logoId,
      logoSelectionFrom: props.logoSelectionFrom,
      totalFavLogos: props.totalFavLogos,
      totalGradientInFav: props.totalGradientInFav,
      industry: props.industry,
      industryKeywords: props.industryKeywords,
      iconId: props.iconId,
      iconSourceName: props.sourceName,
      isPremium: props.isPremium,
      galleryLogoIndex: props.galleryLogoIndex,
      isEngagedWithFontFilter: props.isEngagedWithFontFilter || false,
      royaltyTier: props.royaltyTier,
      iconCreatedBy: props?.iconCreatedBy,
      iconUrl: props?.iconUrl,
    },
  }
}

export type EmailLogoDataGeneratedProps = {
  shopperId: string
  emailColoredLogoUrl: string
  emailTransparentLogoUrl: string
  recommendationUploadUrl: string
  downloadPageLogoUrl?: string
  brandId: string
  businessName: string
}

export const getEmailLogoDataGeneratedEvent = (props: EmailLogoDataGeneratedProps, context: PageContext) => {
  return {
    eventName: 'Email logo data generated',
    payload: {
      label: 'DownloadPage: Email Logo Details',
      ...getProductProperties(),
      ...getPageProperties(context),
      user_shopper_id: props.shopperId,
      email_colored_logo_url: props.emailColoredLogoUrl,
      email_transparent_logo_url: props.emailTransparentLogoUrl,
      recommendations_transparent_logo_url: props.recommendationUploadUrl,
      download_logo_page_url: context.pageUrl,
      brand_id: props.brandId,
      business_name: props.businessName,
    },
  }
}

export type FlyOutViewedEventProps = ProductSpecificEventProps

export const getFlyOutViewedEvent = (props: FlyOutViewedEventProps, pageContext: PageContext) => {
  return {
    eventName: 'Fly-Out Viewed',
    payload: {
      category: 'Fly-Out',
      label: 'Logomaker Download Fly-Out',
      ...getPageProperties(pageContext),
      ...getProductProperties(props),
    },
  }
}

export type FlyOutClosedEventProps = ProductSpecificEventProps

export const getFlyOutClosedEvent = (props: FlyOutClosedEventProps, pageContext: PageContext) => {
  return {
    eventName: 'Fly-Out Closed',
    payload: {
      category: 'Fly-Out',
      label: 'Logomaker Download Fly-Out',
      ...getPageProperties(pageContext),
      ...getProductProperties(props),
    },
  }
}

export type FlyOutClickedPros = {
  optionType: string
  optionSelected: string
} & ProductSpecificEventProps

export const getFlyOutClickedEvent = (props: FlyOutClickedPros, pageContext: PageContext) => {
  return {
    eventName: 'Fly-Out Clicked',
    payload: {
      category: 'Fly-Out',
      label: 'Logomaker Download Product Builder',
      eventDetail: props.optionType,
      variant: props.optionSelected,
      ...getPageProperties(pageContext),
      ...getProductProperties(props),
    },
  }
}

export type ButtonClickedEditInStudioProps = {
  variant: string
} & ProductSpecificEventProps

export const getButtonClickedEditInStudioEvent = (props: ButtonClickedEditInStudioProps, pageContext: PageContext) => {
  return {
    eventName: 'Button Clicked',
    payload: {
      label: 'Edit in studio',
      category: 'Review',
      variant: props.variant,
      ...getPageProperties(pageContext),
      ...getProductProperties(props),
    },
  }
}

export const trackingConfigurationForBrandShop = {
  pageSection: PAGE_SECTION.BRAND_SHOP,
  pageStage: PAGE_STAGE.BRAND_SHOP,
  pageName: `${PAGE_SECTION.BRAND_SHOP}:${PAGE_NAME.BRAND_SHOP}`,
}

export const trackingConfigurationForDownloadPage = {
  pageSection: PAGE_SECTION.DESIGN_SERVICES,
  pageStage: PAGE_STAGE.LOGOMAKER,
  pageName: `${PAGE_NAME.DESIGN_REVIEW}:${PAGE_SECTION.DESIGN_SERVICES}`,
}
