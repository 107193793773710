import { BUSINESS_CARD_MPV_ID } from './app'

export const LOGOS_SERVICE_BASE = process.env.UBIK_LOGOS_API_URL
export const LOGOS_V2_URL = `${LOGOS_SERVICE_BASE}/v2/logos`
export const LOGOMATION_BASE = process.env.UBIK_LOGOMATION_BASE_URL
export const RICH_CUSTOMER_PROFILE_URL = 'https://rich.customer-profile.vpsvc.com/api/v2/customer/me/brand'

export const VISTA_WIX_BASE_URL = process.env.UBIK_VISTA_WIX_BASE_URL
export const URL_SERVICE_BASE_URL = process.env.UBIK_URL_SERVICE_BASE_URL

export const LOGO2LIFE_BACKEND_BASE_URL = 'https://prod-backend.logo2life.vpsvc.com'
export const LOGO_VARIANT_BASE_URL = 'https://logo-variant.algo.design.vpsvc.com'

export const PISANO_USER_FEEDBACK_URL = process.env.UBIK_PISANO_USER_FEEDBACK_URL

export const DomainSuggestionsApiLoggedInUser = (partnerName: string, canonicalId: string, locale: string) =>
  `${VISTA_WIX_BASE_URL}/api/v1/domain/search/${partnerName}/${canonicalId}?locale=${locale}&source=logomaker-domains&requestor=logo2life`

export const UrlServiceApiForExploreMoreDomainsBtn = (locale: string) =>
  `${URL_SERVICE_BASE_URL}/v3/url/vistaprint/${locale}/digital-merchandising:domains-wix-search-and-suggestions?requestor=logo2life`

export const GET_BUSINESS_CARD_PRICE = (locale: string, country: string) => {
  return `https://vpmerchant.dsp.dst.vpsvc.com/v1/Pricing/${locale}/standard?mpvIds=${BUSINESS_CARD_MPV_ID}&market=${country}&isVAT=true`
}

export const LOGO_PREVIEW_URL = (
  imageType: string,
  backgroundMode: string,
  width: number,
  height?: number,
  logoVariation?: string | undefined,
) => {
  let url: string = `${LOGOMATION_BASE}/v1/download/getLogoForPreview?type=${imageType}&width=${width}&backgroundMode=${backgroundMode}`
  if (logoVariation) {
    url += `&logoVariation=${logoVariation}`
  }
  if (height) {
    url += `&height=${height}`
  }
  return url
}

export const LOGO_DOWNLOAD_API_URL = (logoId: string, downloadFormat: string) => {
  return `${LOGOS_SERVICE_BASE}/v1/logos/${logoId}/download?downloadFormat=${downloadFormat}`
}
