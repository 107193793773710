// A utility function to test integration redirections like cross-sell, my uploads etc.
// Will return string with Vistaprint AU base path for all ENVs except PROD.
// On PROD will just return the orgin + input route. Assumes that route has a leading / in it.
export const getRedirectURL = (route: string): string => {
  if (
    window.location.origin.includes('https://www.vistaprint.') ||
    window.location.origin.includes('next.vistaprint.') ||
    window.location.origin.includes('staging.vistaprint')
  ) {
    if (window.location.pathname.includes('/fr/logomaker')) {
      return `/fr${route}`
    }
  }
  return window.location.origin + route
}
