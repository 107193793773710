// From: https://assets.algo.design.vpsvc.com/v1/color-palettes?limit=1000

export interface ColorGradient {
  color1: string
  color2: string
}

export interface VariationsByColorCount {
  supportedVariations: number[]
  iconColorCount: number
}

export interface LogoToolColorPalette {
  id: string
  name: string
  color1: string
  color2: string
  color3: string
  dark: string
  light: string
  version: number
  gradients: [ColorGradient, ColorGradient]
  variations: VariationsByColorCount[]
}

export interface ColorPaletteResponse {
  total: number
  limit: number
  offset: number
  result: LogoToolColorPalette[]
}

export function getColorPalettes(): ColorPaletteResponse {
  return data
}

const data: ColorPaletteResponse = {
  total: 20,
  limit: 1000,
  offset: 1,
  result: [
    {
      id: '618cf0377c84191891e6f8eb',
      name: 'Whiplash',
      color1: '#C84449',
      color2: '#E37F7C',
      color3: '#EFE0E2',
      dark: '#382D5E',
      light: '#FFFFFF',
      variations: [
        {
          supportedVariations: [1, 8, 5, 3, 2],
          iconColorCount: 1,
        },
        {
          supportedVariations: [1, 8, 5, 3, 2],
          iconColorCount: 2,
        },
        {
          supportedVariations: [1],
          iconColorCount: 3,
        },
        {
          supportedVariations: [1],
          iconColorCount: 4,
        },
      ],
      version: 1,
      gradients: [
        {
          color1: '#C84449',
          color2: '#382D5E',
        },
        {
          color1: '#E37F7C',
          color2: '#C84449',
        },
      ],
    },
    {
      id: '618cf1837c84191891e6f8f3',
      name: 'Highly Caffeinated',
      color1: '#AAC527',
      color2: '#D4D6C9',
      color3: '#E9E9E5',
      dark: '#343432',
      light: '#F9F9F9',
      variations: [
        {
          supportedVariations: [2, 6, 8, 1, 4],
          iconColorCount: 1,
        },
        {
          supportedVariations: [2, 8, 6, 1],
          iconColorCount: 2,
        },
        {
          supportedVariations: [1],
          iconColorCount: 3,
        },
        {
          supportedVariations: [1],
          iconColorCount: 4,
        },
      ],
      version: 1,
      gradients: [
        {
          color1: '#AAC527',
          color2: '#343432',
        },
        {
          color1: '#D4D6C9',
          color2: '#343432',
        },
      ],
    },
    {
      id: '618cefc67c84191891e6f8dd',
      name: 'Energy Peak',
      color1: '#B9625D',
      color2: '#C5B3B3',
      color3: '#EDE8E8',
      dark: '#574848',
      light: '#FFFFFF',
      variations: [
        {
          supportedVariations: [1, 3, 8, 2, 6],
          iconColorCount: 1,
        },
        {
          supportedVariations: [1, 8, 3],
          iconColorCount: 2,
        },
        {
          supportedVariations: [1],
          iconColorCount: 3,
        },
        {
          supportedVariations: [1],
          iconColorCount: 4,
        },
      ],
      version: 1,
      gradients: [
        {
          color1: '#B9625D',
          color2: '#574848',
        },
        {
          color1: '#C5B3B3',
          color2: '#574848',
        },
      ],
    },
    {
      id: '618cf2e37c84191891e6f909',
      name: 'Steel Teal',
      color1: '#4E888F',
      color2: '#BAB9B4',
      color3: '#DCE2E3',
      dark: '#404040',
      light: '#FFFFFF',
      variations: [
        {
          supportedVariations: [1, 3, 8, 6, 2],
          iconColorCount: 1,
        },
        {
          supportedVariations: [1, 8, 2, 6, 3],
          iconColorCount: 2,
        },
        {
          supportedVariations: [1],
          iconColorCount: 3,
        },
        {
          supportedVariations: [1],
          iconColorCount: 4,
        },
      ],
      version: 1,
      gradients: [
        {
          color1: '#4E888F',
          color2: '#404040',
        },
        {
          color1: '#BAB9B4',
          color2: '#404040',
        },
      ],
    },
    {
      id: '618cf0d37c84191891e6f8ef',
      name: 'Taste of Italy',
      color1: '#8C1627',
      color2: '#BFBE5F',
      color3: '#E5E5C4',
      dark: '#0E0904',
      light: '#FFFFFF',
      variations: [
        {
          supportedVariations: [1, 3, 6, 8, 2],
          iconColorCount: 1,
        },
        {
          supportedVariations: [1, 3, 8, 6, 2],
          iconColorCount: 2,
        },
        {
          supportedVariations: [1],
          iconColorCount: 3,
        },
        {
          supportedVariations: [1],
          iconColorCount: 4,
        },
      ],
      version: 1,
      gradients: [
        {
          color1: '#8C1627',
          color2: '#0E0904',
        },
        {
          color1: '#BFBE5F',
          color2: '#8C1627',
        },
      ],
    },
    {
      id: '618cf68d7c84191891e6f949',
      name: 'Marvelous',
      color1: '#3682AE',
      color2: '#A5B2BA',
      color3: '#EDA0A0',
      dark: '#102036',
      light: '#FFFFFF',
      variations: [
        {
          supportedVariations: [1, 2, 3, 8, 6],
          iconColorCount: 1,
        },
        {
          supportedVariations: [1, 2, 3, 6],
          iconColorCount: 2,
        },
        {
          supportedVariations: [1],
          iconColorCount: 3,
        },
        {
          supportedVariations: [1],
          iconColorCount: 4,
        },
      ],
      version: 1,
      gradients: [
        {
          color1: '#3682AE',
          color2: '#102036',
        },
        {
          color1: '#EDA0A0',
          color2: '#102036',
        },
      ],
    },
    {
      id: '618cf1357c84191891e6f8f1',
      name: 'Maroon Sunset',
      color1: '#BD2949',
      color2: '#DE6D2E',
      color3: '#E9AE3F',
      dark: '#0E1425',
      light: '#FFFFFF',
      variations: [
        {
          supportedVariations: [1, 3, 2, 5, 8],
          iconColorCount: 1,
        },
        {
          supportedVariations: [1, 2, 3, 8, 5],
          iconColorCount: 2,
        },
        {
          supportedVariations: [1],
          iconColorCount: 3,
        },
        {
          supportedVariations: [1],
          iconColorCount: 4,
        },
      ],
      version: 1,
      gradients: [
        {
          color1: '#BD2949',
          color2: '#0E1425',
        },
        {
          color1: '#E9AE3F',
          color2: '#BD2949',
        },
      ],
    },
    {
      id: '618cf53f7c84191891e6f939',
      name: 'Pumpkin Patch',
      color1: '#D56A34',
      color2: '#C08C46',
      color3: '#EFCA74',
      dark: '#3F170E',
      light: '#F9F7E9',
      variations: [
        {
          supportedVariations: [1, 3, 2, 8, 5],
          iconColorCount: 1,
        },
        {
          supportedVariations: [1, 2, 3, 8, 5],
          iconColorCount: 2,
        },
        {
          supportedVariations: [1],
          iconColorCount: 3,
        },
        {
          supportedVariations: [1],
          iconColorCount: 4,
        },
      ],
      version: 1,
      gradients: [
        {
          color1: '#D56A34',
          color2: '#3F170E',
        },
        {
          color1: '#C08C46',
          color2: '#3F170E',
        },
      ],
    },
    {
      id: '618cf5d77c84191891e6f943',
      name: 'Rich Royal',
      color1: '#3361E1',
      color2: '#51A8DB',
      color3: '#DDDEE0',
      dark: '#000000',
      light: '#FFFFFF',
      variations: [
        {
          supportedVariations: [1, 3, 6, 8, 4],
          iconColorCount: 1,
        },
        {
          supportedVariations: [1, 3, 8, 2, 6],
          iconColorCount: 2,
        },
        {
          supportedVariations: [1],
          iconColorCount: 3,
        },
        {
          supportedVariations: [1],
          iconColorCount: 4,
        },
      ],
      version: 1,
      gradients: [
        {
          color1: '#3361E1',
          color2: '#000000',
        },
        {
          color1: '#51A8DB',
          color2: '#3361E1',
        },
      ],
    },
    {
      id: '618cf6107c84191891e6f945',
      name: 'Big Box',
      color1: '#1192DC',
      color2: '#FACF0E',
      color3: '#FBE585',
      dark: '#202020',
      light: '#FFFFFF',
      variations: [
        {
          supportedVariations: [2, 1, 3, 6, 8],
          iconColorCount: 1,
        },
        {
          supportedVariations: [2, 1, 3, 6, 8],
          iconColorCount: 2,
        },
        {
          supportedVariations: [1],
          iconColorCount: 3,
        },
        {
          supportedVariations: [1],
          iconColorCount: 4,
        },
      ],
      version: 1,
      gradients: [
        {
          color1: '#1192DC',
          color2: '#202020',
        },
        {
          color1: '#FACF0E',
          color2: '#202020',
        },
      ],
    },
    {
      id: '618cf1db7c84191891e6f8fb',
      name: 'Heartland',
      color1: '#3E7742',
      color2: '#B88059',
      color3: '#F7CA4D',
      dark: '#431A10',
      light: '#FFFCF7',
      variations: [
        {
          supportedVariations: [1, 3, 2, 8, 5],
          iconColorCount: 1,
        },
        {
          supportedVariations: [3, 1, 8, 5, 2],
          iconColorCount: 2,
        },
        {
          supportedVariations: [1],
          iconColorCount: 3,
        },
        {
          supportedVariations: [1],
          iconColorCount: 4,
        },
      ],
      version: 1,
      gradients: [
        {
          color1: '#3E7742',
          color2: '#431A10',
        },
        {
          color1: '#F7CA4D',
          color2: '#3E7742',
        },
      ],
    },
    {
      id: '618cf6dd7c84191891e6f94b',
      name: 'Deep Ocean',
      color1: '#225777',
      color2: '#3A868F',
      color3: '#ECECD9',
      dark: '#173054',
      light: '#FFFFFF',
      variations: [
        {
          supportedVariations: [1, 8, 5, 3],
          iconColorCount: 1,
        },
        {
          supportedVariations: [1, 8, 5, 3],
          iconColorCount: 2,
        },
        {
          supportedVariations: [1],
          iconColorCount: 3,
        },
        {
          supportedVariations: [1],
          iconColorCount: 4,
        },
      ],
      version: 2,
      gradients: [
        {
          color1: '#225777',
          color2: '#173054',
        },
        {
          color1: '#3A868F',
          color2: '#225777',
        },
      ],
    },
    {
      id: '618cf2387c84191891e6f8fd',
      name: 'Shiny Shamrock',
      color1: '#67A97B',
      color2: '#A4C868',
      color3: '#FAECD3',
      dark: '#1C3423',
      light: '#FFFFFF',
      variations: [
        {
          supportedVariations: [2, 1, 6, 3, 8, 4],
          iconColorCount: 1,
        },
        {
          supportedVariations: [2, 1, 8, 3, 4],
          iconColorCount: 2,
        },
        {
          supportedVariations: [1],
          iconColorCount: 3,
        },
        {
          supportedVariations: [1],
          iconColorCount: 4,
        },
      ],
      version: 1,
      gradients: [
        {
          color1: '#67A97B',
          color2: '#1C3423',
        },
        {
          color1: '#A4C868',
          color2: '#1C3423',
        },
      ],
    },
    {
      id: '618cf08a7c84191891e6f8ed',
      name: 'Striking Purple',
      color1: '#A5497E',
      color2: '#C771A3',
      color3: '#F2C37C',
      dark: '#390C14',
      light: '#FFFEF5',
      variations: [
        {
          supportedVariations: [1, 2, 3, 5, 8],
          iconColorCount: 1,
        },
        {
          supportedVariations: [1, 3, 5, 8, 2],
          iconColorCount: 2,
        },
        {
          supportedVariations: [1],
          iconColorCount: 3,
        },
        {
          supportedVariations: [1],
          iconColorCount: 4,
        },
      ],
      version: 1,
      gradients: [
        {
          color1: '#A5497E',
          color2: '#390C14',
        },
        {
          color1: '#F2C37C',
          color2: '#A5497E',
        },
      ],
    },
    {
      id: '618cf6527c84191891e6f947',
      name: 'Sea Serpent',
      color1: '#64C6C4',
      color2: '#A4CB6A',
      color3: '#DCEAC6',
      dark: '#212245',
      light: '#FFFFFF',
      variations: [
        {
          supportedVariations: [2, 4, 6, 8, 1],
          iconColorCount: 1,
        },
        {
          supportedVariations: [2, 8, 1],
          iconColorCount: 2,
        },
        {
          supportedVariations: [1],
          iconColorCount: 3,
        },
        {
          supportedVariations: [1],
          iconColorCount: 4,
        },
      ],
      version: 2,
      gradients: [
        {
          color1: '#64C6C4',
          color2: '#212245',
        },
        {
          color1: '#A4CB6A',
          color2: '#212245',
        },
      ],
    },
    {
      id: '618cf48e7c84191891e6f92f',
      name: 'Mellow Drive',
      color1: '#E8A941',
      color2: '#BDBFC3',
      color3: '#FBE5C1',
      dark: '#262626',
      light: '#FFFFFF',
      variations: [
        {
          supportedVariations: [2, 4, 1, 6, 8],
          iconColorCount: 1,
        },
        {
          supportedVariations: [1, 8, 2, 6],
          iconColorCount: 2,
        },
        {
          supportedVariations: [1],
          iconColorCount: 3,
        },
        {
          supportedVariations: [1],
          iconColorCount: 4,
        },
      ],
      version: 1,
      gradients: [
        {
          color1: '#E8A941',
          color2: '#262626',
        },
        {
          color1: '#BDBFC3',
          color2: '#262626',
        },
      ],
    },
    {
      id: '618cf37b7c84191891e6f913',
      name: 'Pastel Brown',
      color1: '#876A57',
      color2: '#D3B39F',
      color3: '#EED9CB',
      dark: '#595959',
      light: '#F2F0F0',
      variations: [
        {
          supportedVariations: [3, 8, 1],
          iconColorCount: 1,
        },
        {
          supportedVariations: [3, 8, 1],
          iconColorCount: 2,
        },
        {
          supportedVariations: [1],
          iconColorCount: 3,
        },
        {
          supportedVariations: [1],
          iconColorCount: 4,
        },
      ],
      version: 1,
      gradients: [
        {
          color1: '#876A57',
          color2: '#595959',
        },
        {
          color1: '#D3B39F',
          color2: '#595959',
        },
      ],
    },
    {
      id: '618cf28d7c84191891e6f8ff',
      name: 'Morning Moss',
      color1: '#769687',
      color2: '#8FB0A1',
      color3: '#D7BB90',
      dark: '#212121',
      light: '#FCF7EE',
      variations: [
        {
          supportedVariations: [1, 2, 6, 8],
          iconColorCount: 1,
        },
        {
          supportedVariations: [1, 8, 6, 2],
          iconColorCount: 2,
        },
        {
          supportedVariations: [1],
          iconColorCount: 3,
        },
        {
          supportedVariations: [1],
          iconColorCount: 4,
        },
      ],
      version: 1,
      gradients: [
        {
          color1: '#769687',
          color2: '#212121',
        },
        {
          color1: '#D7BB90',
          color2: '#212121',
        },
      ],
    },
    {
      id: '618cf42c7c84191891e6f925',
      name: 'Sandy Blush',
      color1: '#E9C3AD',
      color2: '#F4E7E7',
      color3: '#FCF3E7',
      dark: '#8A615C',
      light: '#FFFCFA',
      variations: [
        {
          supportedVariations: [2, 8, 1, 6, 4],
          iconColorCount: 1,
        },
        {
          supportedVariations: [2, 8, 6, 1, 4],
          iconColorCount: 2,
        },
        {
          supportedVariations: [1],
          iconColorCount: 3,
        },
        {
          supportedVariations: [1],
          iconColorCount: 4,
        },
      ],
      version: 1,
      gradients: [
        {
          color1: '#E9C3AD',
          color2: '#8A615C',
        },
        {
          color1: '#F4E7E7',
          color2: '#8A615C',
        },
      ],
    },
    {
      id: '618cf5877c84191891e6f93b',
      name: 'Modern Mustard',
      color1: '#BA8437',
      color2: '#CF9645',
      color3: '#B7B7A5',
      dark: '#000000',
      light: '#FFFDFA',
      variations: [
        {
          supportedVariations: [1, 2, 8, 3, 6],
          iconColorCount: 1,
        },
        {
          supportedVariations: [1, 2, 3, 8],
          iconColorCount: 2,
        },
        {
          supportedVariations: [1],
          iconColorCount: 3,
        },
        {
          supportedVariations: [1],
          iconColorCount: 4,
        },
      ],
      version: 1,
      gradients: [
        {
          color1: '#BA8437',
          color2: '#000000',
        },
        {
          color1: '#CF9645',
          color2: '#000000',
        },
      ],
    },
  ],
}
