import classNames from 'classnames'
import React from 'react'
import { Spinner, useScreenClass } from '@vp/swan'
import classes, { stylesheet } from './Loader.module.scss'
import { useTranslation } from 'react-i18next'
import { useStyles } from '@vp/ubik-context'

const Loader: React.FC<{
  text?: string
  showText?: boolean
  size?: 'standard' | 'super' | 'mini' | 'tiny'
  overlay?: boolean
  layout?: 'vertical' | 'horizontal'
  loaderWrapperClassName?: string
  className?: string
}> = props => {
  useStyles(stylesheet)
  const { t } = useTranslation()
  const screenClass = useScreenClass()
  const defaultLoaderSize: 'standard' | 'super' | 'mini' | 'tiny' = screenClass === 'xs' || screenClass === 'sm' ? 'mini' : 'standard'

  return (
    <div className={classNames(classes.container, props.loaderWrapperClassName)}>
      <Spinner
        accessibleText={props.text || t('globalVariables.defaultLoaderText')}
        showText={props.showText || false}
        size={props.size || defaultLoaderSize}
        layout={props.layout || 'vertical'}
        overlay={props.overlay || false}
        className={classNames(classes.loader, props.className)}
      />
    </div>
  )
}

export default Loader
