import { LOGO_VARIANT_BASE_URL } from '../constants/apiEndpoints'

export type LogoVariationName = 'original' | 'brandMark' | 'wordMark' | 'nonLogoMaker'
export type BackgroundMode = 'TRANSPARENT' | 'COLOR'
export type ImageType = 'PNG' | 'SVG'

export type GenerateLogoVariantUrlOptions = {
  backgroundMode: BackgroundMode
  imageType: ImageType
  logoVariation?: LogoVariationName
  width?: number
  height?: number
  makeSafeOnWhite?: boolean
}

export type GenerateLogoVariantUrl = (options: GenerateLogoVariantUrlOptions) => string

export const createLogoVariantUrlGenerator = (logoId: string, signedToken: string): GenerateLogoVariantUrl => {
  return ({ logoVariation, width, height, backgroundMode, imageType, makeSafeOnWhite }: GenerateLogoVariantUrlOptions) => {
    if (!width && !height) {
      throw new Error('Width or height must be provided')
    }

    const params = new URLSearchParams()
    params.set('sign', signedToken)

    params.set('backgroundMode', backgroundMode)
    params.set('type', imageType)

    if (logoVariation && logoVariation !== 'original') {
      params.set('logoVariation', logoVariation)
    }

    if (width) {
      params.set('width', width + '')
    }

    if (height) {
      params.set('height', height + '')
    }

    if (makeSafeOnWhite) {
      params.set('removeWhiteColor', '1')
    }

    return LOGO_VARIANT_BASE_URL + '/' + logoId + '?' + params.toString()
  }
}
