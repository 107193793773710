import { useUserContext } from '@vp/ubik-context'

const en_USCultures = ['en-CA', 'en-US']
const en_GBCultures = ['en-AU', 'en-IE', 'en-GB', 'en-NZ', 'en-SG', 'en-IN']

export const getCultureCode = (simulatedCulture?: string): string => {
  return simulatedCulture || useUserContext().locale
}

export const getLocaleFromCulture = (simulatedCulture?: string): string => {
  let cultureCode = simulatedCulture || getCultureCode()
  if (en_USCultures.indexOf(cultureCode) !== -1) {
    cultureCode = 'en-US'
  } else if (en_GBCultures.indexOf(cultureCode) !== -1) {
    cultureCode = 'en-GB'
  }
  return cultureCode
}

export const getCountryFromLocale = () => {
  const locale = getCultureCode()
  const parts = locale.split('-')
  return parts.length > 1 ? parts[1] : null
}

export const getAlgoliaLabelByCultureCode = (simulatedCulture?: string): string => {
  let cultureCode = getLocaleFromCulture(simulatedCulture)
  if (cultureCode === 'de-AT' || cultureCode === 'de-CH') {
    cultureCode = 'de-DE'
  } else if (cultureCode === 'fr-CH') {
    cultureCode = 'fr-FR'
  }
  return cultureCode.replaceAll('-', '_')
}
