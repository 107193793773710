import { v4 as uuidv4 } from 'uuid'
import { DesignSurface } from '@mcp-artwork/cimdoc-types-v2/dist/DesignSurface'
import { PanelContract } from './targetSpecifications/types'

export const generateSecondaryPanel = (panel: PanelContract): DesignSurface => {
  const panelId = uuidv4()

  let panelWidth = panel.fullBleed.dimensions.width.value
  let panelHeight = panel.fullBleed.dimensions.height.value

  return {
    id: `${panelId}`,
    name: panel.name || 'Back',
    width: `${panelWidth}mm`,
    height: `${panelHeight}mm`,
    decorationTechnology: panel.decorationTechnology || 'inkJet',
    colorMode: 'blank',
  }
}
