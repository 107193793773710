// TODO: There isn't a convenient way to get all of the concept ID's.
// So for now we will just list them explicitly here.

export const ALL_DESIGN_CONCEPT_IDS = [
  '9c375b2f-d1e3-4057-9345-95829edce9e7',
  '37842',
  '37163',
  '30842',
  '35711',
  '37473',
  '19672',
  '25866',
  '26444',
  '28263',
  '29665',
  '30554',
  '30877',
  // '32279', // Lawnmower design with a stripe in the middle of the background
  '32850',
  '33516',
  '35642',
  '35778',
  '36992',
  '37294',
  '37330',
  '37739',
  '37798',
  '37869',
  '37870',
  '37969',
  '37984',
  '37999',
  '3e4c1e98-e136-405b-a102-a9bf2b755ce9',
  '3f675ef2-55be-4ad0-af3c-c1bbac79804e',
  '71024804-140c-4dad-8db1-eacb69967f5f',
  'f12348c5-950d-4333-8592-35544fc3fa69',
  '08a7c854-b335-4c53-a605-89f931eac031',
  '11056',
  '16877',
  '18503',
  '19424',
  // '19990', // Has white text on a whie backgrounc
  '23181',
  // '24892', // Has white text on white background
  '24900',
  '25556',
  '25867',
  '26494',
  '26814',
  '27582',
  '28069',
  '28345',
  '29661',
  '29869',
  '30452',
  '30742',
  '30822',
  '32081',
  '33956',
  '35581',
  '35743',
  '35800',
  '35839',
  '35849',
  '3631750',
  '36376',
  '36981',
  '36995',
  '37219',
  '37617',
  '37690',
  '37704',
  '37718',
  '37975',
  '37986',
  '37993',
  '37996',
  '37998',
  '38001',
  '38003',
  '38010',
  '38016',
  '3865654',
  '439e9daf-e991-4a96-86b4-879c2ab1dcb6',
  '79a6e23e-2959-46f3-b43d-9c14136e9b36',
  '89e03556-fd20-4f56-9140-df5113b0e077',
  '8bc984a8-b5ca-47eb-aece-64c3fa28608e',
  '9fe10c97-1186-45b1-82c2-27689e6ed96f',
  'ca244342-510d-4627-b58e-3224a56ffaf2',
  '12902',
  '24893',
  '5655',
  '7299',
]
