import { getCultureCode } from '../utilities/culture'

interface CultureConfig {
  [key: string]: {
    homeURL?: string
    downloadPageWidgets?: string[]
  }
}

const cultureConfig: CultureConfig = {
  'fr-FR': {
    homeURL: '/logo/creation-logo',
    downloadPageWidgets: ['PRINT', 'DOMAIN', 'DESIGN'],
  },
  'sv-SE': {
    homeURL: '/logo/creation-logo',
    downloadPageWidgets: ['PRINT', 'DOMAIN', 'DESIGN'],
  },
  'fr-CA': {
    homeURL: '/fr/logomaker',
    downloadPageWidgets: ['PRINT', 'DOMAIN', 'DESIGN'],
  },
  'en-US': {
    homeURL: '/logomaker',
    downloadPageWidgets: ['PRINT', 'DOMAIN', 'DESIGN'],
  },
  'fr-CH': {
    homeURL: '/fr/logomaker',
    downloadPageWidgets: ['PRINT', 'DOMAIN', 'DESIGN'],
  },
  'en-IN': {
    homeURL: '/logomaker',
    downloadPageWidgets: ['PRINT', 'DESIGN'],
  },
  default: {
    homeURL: '/logomaker',
    downloadPageWidgets: ['PRINT', 'DOMAIN', 'DESIGN'],
  },
}

export const getCultureSpecificConfig = () => {
  const cultureName = getCultureCode()
  return cultureConfig[cultureName] || cultureConfig.default
}
