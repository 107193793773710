import { CimpressDocument } from '@mcp-artwork/cimdoc-types-v2'
import { deflateSync } from 'fflate'

export function generatePreviewURI(document: CimpressDocument, width: string, sceneId: string, mpvId?: string, imageFormat?: string) {
  const encodedDocumentStr = encodeCimDoc(document) // Use the updated encoding function
  const updatedSceneId = mpvId === 'personalizedMugs' ? 'aa659019-61a3-462f-80be-2924c538e80a' : sceneId
  const instructionsURI = `https://instructions.documents.cimpress.io/v3/instructions:preview?documentUri=${encodedDocumentStr}`
  const sceneUri = `https://assets.documents.cimpress.io/v3/assets/${updatedSceneId}/content`
  const previewURI = `https://rendering.documents.cimpress.io/v2/example/preview?width=${width}&instructions_uri=${encodeURIComponent(instructionsURI)}&scene=${encodeURIComponent(sceneUri)}`
  if (imageFormat) {
    return `${previewURI}&format=${imageFormat}`
  }
  return previewURI
}

export const isPPAGProduct = (mpvId: string) => {
  const PPAGKeywords = ['shirt', 'hoodie', 'cap', 'jacket']
  return PPAGKeywords.some(keyword => mpvId.toLowerCase().includes(keyword))
}

export function generateDocumentTransientURL(document: CimpressDocument) {
  if (!document || Object.keys(document).length === 0) {
    throw new Error('CimDoc is empty or not provided.')
  }
  const encodedDocumentStr = encodeCimDoc(document) // Use the updated encoding function
  const documentTransientURL = `https://storage.documents.cimpress.io/v3/documents/transient?document=${encodedDocumentStr}`
  return documentTransientURL
}

const encodeCimDoc = (cimDoc: CimpressDocument): string => {
  const documentJson = JSON.stringify(cimDoc)
  const encoder = new TextEncoder()
  const encodedValue = encoder.encode(documentJson)
  const valueDeflated = deflateSync(encodedValue)

  // Base64 encode without using Buffer
  const base64String = btoa(String.fromCharCode(...new Uint8Array(valueDeflated)))

  // Return the URL-encoded Base64 string
  return encodeURIComponent(base64String)
}
