import { convertHexColorToRGB } from '@mcp-artwork/color-converter'
import { LogoToolColorPalette, ColorPaletteVariation, getColorPaletteVariations, getColorPalettes } from './data'
import { rgbToHex } from '../../dynamic-design-engine'

export function getColorPaletteVariationNumbers(colorPalette: LogoToolColorPalette): number[] {
  return colorPalette.variations.find(v => v.iconColorCount === 1)!.supportedVariations
}

export function getColorPaletteVariation(variationNumber: number): ColorPaletteVariation {
  const result = getColorPaletteVariations().result.find(variation => variation.variationNumber === variationNumber)
  if (result === undefined) {
    throw new Error('Color Palette Variation not found')
  }
  return result
}

export function getDefaultVariationForColorPalette(colorPalette: LogoToolColorPalette): ColorPaletteVariation {
  const variationNumber = getColorPaletteVariationNumbers(colorPalette)[0]
  return getColorPaletteVariation(variationNumber)
}

export function getNextVariationForColorPalette(
  colorPalette: LogoToolColorPalette,
  currentVariation: ColorPaletteVariation,
): ColorPaletteVariation {
  const variationNumbers = getColorPaletteVariationNumbers(colorPalette)
  const currentVariationIndex = variationNumbers.findIndex(variationNumber => variationNumber === currentVariation.variationNumber)
  const nextVariationIndex = (currentVariationIndex + 1) % variationNumbers.length
  const nextVariationNumber = variationNumbers[nextVariationIndex]
  return getColorPaletteVariation(nextVariationNumber)
}

export const defaultColorPalette = getColorPalettes().result[0]

export const defaultColorPaletteVariation = getDefaultVariationForColorPalette(defaultColorPalette)

export function makeColorLighterOrDarker(color: string, amount?: number) {
  const amountToUse = amount ?? 15
  const { r, g, b } = convertHexColorToRGB(color)
  if (r + g + b < 255) {
    const r2 = Math.min(r + amountToUse, 255)
    const g2 = Math.min(g + amountToUse, 255)
    const b2 = Math.min(b + amountToUse, 255)
    return rgbToHex({ r: r2, g: g2, b: b2 })
  }
  const r2 = Math.max(r - amountToUse, 0)
  const g2 = Math.max(g - amountToUse, 0)
  const b2 = Math.max(b - amountToUse, 0)
  return rgbToHex({ r: r2, g: g2, b: b2 })
}
