import { RectangleItem } from '@mcp-artwork/cimdoc-types-v2'
import { v4 as uuidv4 } from 'uuid'
import { DesignSurface } from '@mcp-artwork/cimdoc-types-v2/dist/DesignSurface'
import { ImageWithDimensions } from '../image'
import { PanelContract } from './targetSpecifications/types'

const MAIN_PANEL_SCALE_FACTOR = 0.8

export const generateMainPanel = (
  panel: PanelContract,
  logo: ImageWithDimensions,
  backgroundColor: string,
  panelId: string,
): DesignSurface => {
  const shapeId = uuidv4()
  const imageId = uuidv4()

  const { fullBleed, safeArea } = panel

  // Use safeArea dimensions if available, otherwise default to fullBleed
  let panelWidth = fullBleed.dimensions.width.value
  let panelHeight = fullBleed.dimensions.height.value

  // Apply scale factor to panel dimensions
  const scaledPanelWidth = panelWidth * MAIN_PANEL_SCALE_FACTOR - (safeArea?.x.value * 2 || 0)
  const scaledPanelHeight = panelHeight * MAIN_PANEL_SCALE_FACTOR - (safeArea?.y.value * 2 || 0)

  // Calculate image dimensions while maintaining aspect ratio
  const imageAspectRatio = logo.width / logo.height
  const panelAspectRatio = scaledPanelWidth / scaledPanelHeight
  let imageWidthMm: number
  let imageHeightMm: number

  if (imageAspectRatio > panelAspectRatio) {
    imageWidthMm = scaledPanelWidth
    imageHeightMm = imageWidthMm / imageAspectRatio
  } else {
    imageHeightMm = scaledPanelHeight
    imageWidthMm = imageHeightMm * imageAspectRatio
  }

  // Calculate positions to center the image within the panel
  const xPosition = (panelWidth - imageWidthMm) / 2
  const yPosition = (panelHeight - imageHeightMm) / 2

  return {
    id: `${panelId}`,
    decorationTechnology: panel.decorationTechnology || 'inkJet',
    name: panel.name || 'Front',
    width: `${panelWidth}mm`,
    height: `${panelHeight}mm`,
    colorMode: 'color',
    shapes: [
      {
        id: `${shapeId}`,
        zIndex: 1,
        type: 'rectangle',
        position: {
          x: `0mm`,
          y: `0mm`,
          width: `${panelWidth}mm`,
          height: `${panelHeight}mm`,
        },
        color: backgroundColor !== 'transparent' ? `rgb(${backgroundColor})` : `rgba(0,0,0,0)`,
      } as RectangleItem,
    ],
    images: [
      {
        id: `${imageId}`,
        zIndex: 2,
        pageNumber: 1,
        previewUrl: logo.url,
        printUrl: logo.url,
        position: {
          x: `${xPosition}mm`,
          y: `${yPosition}mm`,
          width: `${imageWidthMm}mm`,
          height: `${imageHeightMm}mm`,
        },
        rotationAngle: '0',
      },
    ],
  }
}
