import { RectangleItem } from '@mcp-artwork/cimdoc-types-v2'
import { v4 as uuidv4 } from 'uuid'
import { DesignSurface } from '@mcp-artwork/cimdoc-types-v2/dist/DesignSurface'
import { ImageWithDimensions } from '../image'
import { PanelContract } from './targetSpecifications/types'

export const generatePpagPanel = (
  panel: PanelContract,
  logo: ImageWithDimensions,
  backgroundColor: string,
  panelId: string,
): DesignSurface => {
  const shapeId = uuidv4()
  const imageId = uuidv4()

  const { fullBleed, safeArea } = panel

  // Use safeArea dimensions if available, otherwise default to fullBleed
  let panelWidth = fullBleed.dimensions.width.value
  let panelHeight = fullBleed.dimensions.height.value

  // Apply scale factor to panel dimensions
  const safeAreaWidth = (safeArea || fullBleed).dimensions.width.value
  const safeAreaHeight = (safeArea || fullBleed).dimensions.height.value

  const imageAspectRatio = logo.width / logo.height
  const safeAreaAspectRatio = safeAreaWidth / safeAreaHeight

  let imageWidth: number
  let imageHeight: number
  if (imageAspectRatio > safeAreaAspectRatio) {
    imageWidth = safeAreaWidth * 0.7
    imageHeight = imageWidth / imageAspectRatio
  } else {
    imageHeight = safeAreaHeight * 0.7
    imageWidth = imageHeight * imageAspectRatio
  }

  const imagePosX = (safeAreaWidth - imageWidth) / 2
  const imagePosY = (safeAreaHeight - imageHeight) / 2

  const shapeWidth = imageWidth + 10
  const shapeHeight = imageHeight + 10
  const shapePosX = imagePosX - 5
  const shapePosY = imagePosY - 5

  return {
    id: `${panelId}`,
    decorationTechnology: panel.decorationTechnology || 'inkJet',
    name: panel.name || 'Front',
    width: `${panelWidth}mm`,
    height: `${panelHeight}mm`,
    colorMode: 'color',
    shapes: [
      {
        id: `${shapeId}`,
        zIndex: 1,
        type: 'rectangle',
        position: {
          x: `${shapePosX}mm`,
          y: `${shapePosY}mm`,
          width: `${shapeWidth}mm`,
          height: `${shapeHeight}mm`,
        },
        color: backgroundColor === 'transparent' ? `rgba(0,0,0,0)` : `rgb(${backgroundColor})`,
      } as RectangleItem,
    ],
    images: [
      {
        id: `${imageId}`,
        zIndex: 2,
        pageNumber: 1,
        previewUrl: logo.url,
        printUrl: logo.url,
        position: {
          x: `${imagePosX}mm`,
          y: `${imagePosY}mm`,
          width: `${imageWidth}mm`,
          height: `${imageHeight}mm`,
        },
        rotationAngle: '0',
      },
    ],
  }
}
