import { BoundedContent } from '@vp/swan'
import classNames from 'classnames'
import { FC } from 'react'
import { VpLogoColoredWithText } from '../../constants/icons'
import classes, {stylesheet} from './VistaLogoHeader.module.scss'
import { useStyles } from '@vp/ubik-context'

export const VistaLogoHeader: FC<{
  logoRedirectionUrl?: string
}> = ({ logoRedirectionUrl = '' }) => {
  useStyles(stylesheet)
  return (
    <nav className={classNames(classes.vpHeaderTextContainer, classes.container)}>
      <BoundedContent style={{ width: '100%' }}>
        <div className={classes.leftNav}>
          {logoRedirectionUrl ? (
            <a aria-label="home" href={`${logoRedirectionUrl}`}>
              <VpLogoColoredWithText />
            </a>
          ) : (
            <VpLogoColoredWithText />
          )}
        </div>
      </BoundedContent>
    </nav>
  )
}
