import { ColorPalette, ColorPalettePart, LogoTextColorPaletteType } from '../types/colors'
import { SmartDesignEngineColorPalette, SmartDesignEngineColorPalettePart } from '../types/smartDesignEngine'
import {
  getBackgroundColorFromSignature,
  getIconColorsFromSignature,
  getMonogramColorsFromSignature,
  getPrimaryTextColorFromSignature,
  getSecondaryTextColorFromSignature,
  getTextContainerColorFromSignature,
  isTextLogo,
} from './signatureParser'
import { makeColorLighterOrDarker } from '../playground/dynamic-design/dynamic-design-spec-generation'
import { colord, extend as extendColord } from 'colord'
import labPlugin from 'colord/plugins/lab'
import a11yPlugin from 'colord/plugins/a11y'
import { BrandProfile } from '../types/brand'
import { COLOR_BLACK, COLOR_WHITE, LOGOMAKER_GRAY_FOR_WHITE_LOGOS, TRANSPARENT_BACKGROUND_PATTERN_GRAY } from '../constants/color'
import { checkIfLogoIsLogoMakerLogo, fetchBrandColorPalette, getLogoBackgroundColorFromBrandProfile } from './brandDataParser'
import { LogoSignature } from '../types/logo'
import { ProductOptions } from '@vp/recommendations-types'
import { ComposeLogoRequest } from '@vp/algo-design-logo-composer'

extendColord([labPlugin, a11yPlugin])

export const toCheckIsTextReadable = (productOptions: ProductOptions, logoTextColorPaletteFromSignature: LogoTextColorPaletteType) => {
  const baseColor = productOptions['Substrate Color']

  const isReadable = (color: string) => colord(color).isReadable(baseColor) || false

  const primaryResult = isReadable(logoTextColorPaletteFromSignature.primary!)
  const secondaryResult = logoTextColorPaletteFromSignature.secondary ? isReadable(logoTextColorPaletteFromSignature.secondary) : true

  return primaryResult && secondaryResult
}

export const generateDefaultColorPalettesFromSignature = (signature: LogoSignature): ColorPalette[] => {
  const generatedPalettes = [
    ...usePermutationsStrategy(signature, 2),
    ...useAveragedIntensityColorIconStrategy(signature),
    ...useColorMonogramStrategy(signature),
  ].filter(onlyUniqueColorPalettes)
  const filteredPalettes = generatedPalettes.filter(identicalForegroundBackground)
  return (filteredPalettes.length > 0 ? filteredPalettes : generatedPalettes).slice(0, 6)
}

const readableColor = (foregroundColor: string, backgroundColor: string) => {
  if (colord(foregroundColor).isReadable(colord(backgroundColor))) {
    return foregroundColor
  }
  return LOGOMAKER_GRAY_FOR_WHITE_LOGOS
}

export const generateColorPalettesForProductsWithNoTemplates = (signature: LogoSignature): ColorPalette[] => {
  const logoContainerColorPalette = extractLogoContainerColorPaletteFromSignature(signature)
  const transparentColorPalettePart: ColorPalettePart = {
    backgroundColor: 'transparent',
    foregroundColor: readableColor(logoContainerColorPalette.foregroundColor, TRANSPARENT_BACKGROUND_PATTERN_GRAY),
  }

  const onePartPalette = {
    name: '1-color',
    parts: [logoContainerColorPalette, logoContainerColorPalette, logoContainerColorPalette],
  }

  const transparentPalette = {
    name: 'transparent',
    parts: [transparentColorPalettePart, transparentColorPalettePart, transparentColorPalettePart],
  }

  return [onePartPalette, transparentPalette]
}

export const generateBrandColorPalettesWithNoTemplates = (brandProfile: BrandProfile): Record<string, ColorPalette[]> => {
  const readableColor = (foreground: string, background: string): string => {
    return background === 'transparent' ? foreground : background
  }

  // Function to generate color palettes without templates for each logo
  const generateColorPalettesForLogo = (backgroundColor: string): ColorPalette[] => {
    const logoContainerColorPalette = {
      backgroundColor: backgroundColor,
      foregroundColor: readableColor(COLOR_BLACK, backgroundColor),
    }

    const transparentColorPalettePart: ColorPalettePart = {
      backgroundColor: 'transparent',
      foregroundColor: readableColor(logoContainerColorPalette.foregroundColor, TRANSPARENT_BACKGROUND_PATTERN_GRAY),
    }

    const onePartPalette: ColorPalette = {
      name: '1-color',
      parts: [logoContainerColorPalette, logoContainerColorPalette, logoContainerColorPalette],
    }

    const transparentPalette: ColorPalette = {
      name: 'transparent',
      parts: [transparentColorPalettePart, transparentColorPalettePart, transparentColorPalettePart],
    }

    return [onePartPalette, transparentPalette]
  }

  // Initialize the result record
  const colorPalettesRecord: Record<string, ColorPalette[]> = {}

  // Iterate through logos and generate color palettes for each logo
  brandProfile.facts.designIdentity.logos.forEach(logo => {
    // Get the background color specific to this logo
    const logoBackgroundColor = getLogoBackgroundColorFromBrandProfile(brandProfile, logo.logoId)

    const colorPalettes = generateColorPalettesForLogo(logoBackgroundColor)

    // Store the color palettes in the record with the logoId as the key
    colorPalettesRecord[logo.logoId] = colorPalettes
  })

  return colorPalettesRecord
}

export const usePermutationsStrategy = (signature: LogoSignature, maxNumberOfColors: number): ColorPalette[] => {
  if (isTextLogo(signature)) {
    return useTextLogoStrategy(signature)
  }

  const logoContainerColorPalette = extractLogoContainerColorPaletteFromSignature(signature)
  const complementaryColorPalettes = extractComplementaryColorPalettesFromSignature(signature)

  const onePartPalette = {
    name: '1-color',
    parts: [logoContainerColorPalette, logoContainerColorPalette, logoContainerColorPalette],
  }

  const twoPartsPalettes = complementaryColorPalettes.map((palette, index) => {
    return {
      name: index === 0 ? '2-colors-primary' : '2-colors-secondary',
      parts: [logoContainerColorPalette, palette, palette],
    }
  })

  const threePartsColorPalettes = []
  if (maxNumberOfColors >= 3) {
    if (complementaryColorPalettes[0] && complementaryColorPalettes[1]) {
      threePartsColorPalettes.push({
        name: '3-colors-primary',
        parts: [logoContainerColorPalette, complementaryColorPalettes[0], complementaryColorPalettes[1]],
      })

      threePartsColorPalettes.push({
        name: '3-colors-secondary',
        parts: [logoContainerColorPalette, complementaryColorPalettes[1], complementaryColorPalettes[0]],
      })
    }
  }

  return [...twoPartsPalettes, onePartPalette, ...threePartsColorPalettes]
}

const useTextLogoStrategy = (signature: LogoSignature): ColorPalette[] => {
  const logoContainerColorPalette = extractLogoTextColorPaletteFromSignature(signature)

  const inverted = {
    backgroundColor: logoContainerColorPalette.foregroundColor,
    foregroundColor: logoContainerColorPalette.backgroundColor,
  }

  return [
    {
      name: '2-colors-primary',
      parts: [logoContainerColorPalette, inverted, inverted],
    },
    {
      name: '2-colors-secondary',
      parts: [logoContainerColorPalette, logoContainerColorPalette, logoContainerColorPalette],
    },
  ]
}

export const usePermutations25Strategy = (signature: LogoSignature): ColorPalette[] => {
  const logoContainerColorPalettePart = extractLogoContainerColorPaletteFromSignature(signature)
  const complementaryColorPaletteParts = extractComplementaryColorPalettesFromSignature(signature)

  const onePartPalette = {
    name: '1-color',
    parts: [logoContainerColorPalettePart, logoContainerColorPalettePart, logoContainerColorPalettePart],
  }

  const twoPartsPalettes = complementaryColorPaletteParts.map((palette, index) => {
    return {
      name: index === 0 ? '2-colors-primary' : '2-colors-secondary',
      parts: [logoContainerColorPalettePart, palette, palette],
    }
  })

  const mod25Parts = complementaryColorPaletteParts.map(part => {
    const modifiedPalette: ColorPalettePart = {
      backgroundColor: makeColorLighterOrDarker(logoContainerColorPalettePart.backgroundColor),
      foregroundColor: logoContainerColorPalettePart.foregroundColor,
    }
    return {
      name: '2-colors-alternative',
      parts: [logoContainerColorPalettePart, part, modifiedPalette],
    }
  })

  return [...twoPartsPalettes, onePartPalette, ...mod25Parts]
}

export const useAveragedColorIconStrategy = (signature: LogoSignature): ColorPalette[] => {
  return useCustomColorIconStrategy(signature, averageColors)
}

export const useIntensityColorIconStrategy = (signature: LogoSignature): ColorPalette[] => {
  return useCustomColorIconStrategy(signature, sortByIntensity)
}

export const useAveragedIntensityColorIconStrategy = (signature: LogoSignature): ColorPalette[] => {
  return useCustomColorIconStrategy(signature, averageOfIntensity)
}

export const useColorMonogramStrategy = (signature: LogoSignature): ColorPalette[] => {
  if (isTextLogo(signature)) {
    return useTextLogoStrategy(signature)
  }

  const logoContainerColorPalette = extractLogoContainerColorPaletteFromSignature(signature)
  const monogramColors = getMonogramColorsFromSignature(signature)
  const possibleForegroundColors = [...monogramColors, logoContainerColorPalette.foregroundColor, logoContainerColorPalette.backgroundColor]

  return monogramColors.map((color, index) => {
    const monogramColorPart: ColorPalettePart = {
      backgroundColor: color,
      foregroundColor: sortForegroundByContrast([color], possibleForegroundColors)[0],
    }

    return {
      name: '2-colors-icon-' + index,
      parts: [logoContainerColorPalette, monogramColorPart, monogramColorPart],
    }
  })
}

type AggregationFunc = (colors: string[]) => string

export const useCustomColorIconStrategy = (signature: LogoSignature, aggregationStrategy: AggregationFunc): ColorPalette[] => {
  const logoContainerColorPalette = extractLogoContainerColorPaletteFromSignature(signature)
  const iconColors = sortFilterAndAggregateColors(getIconColorsFromSignature(signature), aggregationStrategy)
  const possibleForegroundColors = [...iconColors, logoContainerColorPalette.foregroundColor, logoContainerColorPalette.backgroundColor]

  return iconColors.map((color, index) => {
    const iconColorPart: ColorPalettePart = {
      backgroundColor: color,
      foregroundColor: sortForegroundByContrast([color], possibleForegroundColors)[0],
    }

    return {
      name: '2-colors-icon-' + index,
      parts: [logoContainerColorPalette, iconColorPart, iconColorPart],
    }
  })
}

const sortFilterAndAggregateColors = (colors: string[], aggregationFunc: AggregationFunc) => {
  colors.sort(hueDistance)
  colors = colors.filter(removeBoringColors)

  const MAX_HUE_DISTANCE = -10

  const colorsGroupedByHue: string[][] = []
  let currentGroupIndex = 0
  let previousHue = ''
  for (let i = 0; i < colors.length; i++) {
    if (i == 0) {
      colorsGroupedByHue[currentGroupIndex] = [colors[i]]
    } else if (hueDistance(previousHue, colors[i]) < MAX_HUE_DISTANCE) {
      currentGroupIndex++
      colorsGroupedByHue[currentGroupIndex] = [colors[i]]
    } else {
      colorsGroupedByHue[currentGroupIndex].push(colors[i])
    }
    previousHue = colors[i]
  }

  return colorsGroupedByHue.map(colors => aggregationFunc(colors))
}

export const generateColorPalettesFromBrandColorPalettes = (
  brandProfile: BrandProfile,
  signature: ComposeLogoRequest | undefined,
): Record<string, ColorPalette[]> => {
  // Fetch brand color palettes
  const brandColorPalettes = fetchBrandColorPalette(brandProfile!)

  // Extract color parts from the brand palettes
  const colorPaletteParts: ColorPalettePart[] = brandColorPalettes.flatMap(brandPalette =>
    brandPalette.colors.map(color => ({
      backgroundColor: color ? color.value : COLOR_WHITE,
      foregroundColor: getContrastedForegroundColor(color.value),
    })),
  )

  // Background part definition for each logo (based on its own background color)
  const generateBackgroundPart = (backgroundColor: string): ColorPalettePart => ({
    backgroundColor: backgroundColor,
    foregroundColor: getContrastedForegroundColor(backgroundColor),
  })

  // One-Part Palette (for background-only palette)
  const generateOnePartPalette = (backgroundColor: string): ColorPalette => ({
    name: '1-color',
    parts: [generateBackgroundPart(backgroundColor), generateBackgroundPart(backgroundColor), generateBackgroundPart(backgroundColor)],
  })

  // Two-Parts Palettes (based on color parts extracted)
  const generateTwoPartsPalettes = (backgroundColor: string): ColorPalette[] => {
    return colorPaletteParts.map((colorPart, index) => ({
      name: `2-colors-${index}`,
      parts: [generateBackgroundPart(backgroundColor), colorPart, colorPart],
    }))
  }

  // Initialize an empty object to hold the record
  const colorPalettesRecord: Record<string, ColorPalette[]> = {}

  // Iterate through logos and generate color palettes per logo
  brandProfile.facts.designIdentity.logos.forEach(logo => {
    if (signature && checkIfLogoIsLogoMakerLogo(logo)) {
      colorPalettesRecord[logo.logoId] = generateDefaultColorPalettesFromSignature(signature)
      return
    }
    // Get the background color specific to this logo
    const logoBackgroundColor = getLogoBackgroundColorFromBrandProfile(brandProfile, logo.logoId)

    // Generate all palettes for the logo
    const onePartPalette = generateOnePartPalette(logoBackgroundColor)
    const twoPartsPalettes = generateTwoPartsPalettes(logoBackgroundColor)

    // Combine all palettes for this logo, limiting to a max of 6
    const allPalettes = [...twoPartsPalettes, onePartPalette].slice(0, 6)

    const uniquePalettes = []
    const seenCombinations = new Set<string>()

    for (const palette of allPalettes) {
      const partsKey = JSON.stringify(palette.parts)

      if (!seenCombinations.has(partsKey)) {
        seenCombinations.add(partsKey)
        uniquePalettes.push(palette)
      }
    }

    // Store the color palettes in the record, using logoId as the key
    colorPalettesRecord[logo.logoId] = uniquePalettes
  })

  return colorPalettesRecord
}

export const removeBoringColors = (color: string) => {
  const hsv = colord(color).toHsv()

  if (hsv.s < 10) {
    // too close to white
    return false
  }

  if (hsv.v < 30) {
    // too dark
    return false
  }

  return true
}

export const hueDistance = (color1: string, color2: string) => {
  return colord(color1).toHsv().h - colord(color2).toHsv().h
}

const averageOfIntensity = (colors: string[]) => {
  colors = colors.filter(color => {
    return colord(color).toHsv().s + colord(color).toHsv().v > 50
  })

  return averageColors(colors)
}

const sortByIntensity = (colors: string[]) => {
  colors.sort((a, b) => {
    const aScore = colord(a).toHsv().s + colord(a).toHsv().v
    const bScore = colord(b).toHsv().s + colord(b).toHsv().v

    return bScore - aScore
  })

  return colors[0]
}

export function averageColors(colors: string[]) {
  const channelSums = [0, 0, 0] // Red, Green, Blue sums

  for (const color of colors) {
    const { r, g, b } = colord(color).toRgb()
    channelSums[0] += r
    channelSums[1] += g
    channelSums[2] += b
  }

  const numColors = colors.length
  const averageColor =
    '#' +
    channelSums
      .map(val =>
        Math.floor(val / numColors)
          .toString(16)
          .padStart(2, '0'),
      )
      .join('')

  return averageColor
}

const extractLogoContainerColorPaletteFromSignature = (signature: LogoSignature): ColorPalettePart => {
  return {
    backgroundColor: getBackgroundColorFromSignature(signature),
    foregroundColor: getPrimaryTextColorFromSignature(signature),
  }
}

const extractLogoTextColorPaletteFromSignature = (signature: LogoSignature): ColorPalettePart => {
  return {
    backgroundColor: getBackgroundColorFromSignature(signature),
    foregroundColor: getTextContainerColorFromSignature(signature),
  }
}

const extractComplementaryColorPalettesFromSignature = (signature: LogoSignature): ColorPalettePart[] => {
  const logoBackgroundColor = getBackgroundColorFromSignature(signature)
  const colors = [getPrimaryTextColorFromSignature(signature), getSecondaryTextColorFromSignature(signature)]
    .filter(Boolean)
    .filter(onlyUnique)

  return colors.map(color => {
    return {
      backgroundColor: color,
      foregroundColor: logoBackgroundColor,
    }
  })
}

export function getContrastedForegroundColor(hex: string) {
  let r = parseInt(hex.slice(1, 3), 16)
  let g = parseInt(hex.slice(3, 5), 16)
  let b = parseInt(hex.slice(5, 7), 16)

  // Calculate luminance
  let luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b

  // Return black or white based on luminance
  return luminance > 128 ? COLOR_BLACK : COLOR_WHITE
}

export const convertColorPaletteToSmartEngineColorPalette = (colorPalette: ColorPalette): SmartDesignEngineColorPalette => {
  return {
    parts: colorPalette.parts.map(part => convertColorPalettePartToSmartEngineColorPalettePart(part)),
  }
}

const convertColorPalettePartToSmartEngineColorPalettePart = ({
  foregroundColor,
  backgroundColor,
}: ColorPalettePart): SmartDesignEngineColorPalettePart => {
  return {
    container: [backgroundColor],
    accent: [foregroundColor],
    icon: [foregroundColor],
    logo: [foregroundColor],
    qrcode: [foregroundColor],
    text1: {
      fill: foregroundColor,
      stroke: foregroundColor,
      shadow: foregroundColor,
    },
    text2: {
      fill: foregroundColor,
      stroke: foregroundColor,
      shadow: foregroundColor,
    },
    text3: {
      fill: foregroundColor,
      stroke: foregroundColor,
      shadow: foregroundColor,
    },
  }
}

function onlyUnique(value: string, index: number, array: string[]) {
  return array.indexOf(value) === index
}

function onlyUniqueColorPalettes(currentPalette: ColorPalette, index: number, palettes: ColorPalette[]) {
  const MAX_DELTAE2000_SIMILARITY_SCORE = 0.15
  return (
    palettes.findIndex(
      palette => colord(currentPalette.parts[1].backgroundColor).delta(palette.parts[1].backgroundColor) < MAX_DELTAE2000_SIMILARITY_SCORE,
    ) === index
  )
}

function identicalForegroundBackground(currentPalette: ColorPalette) {
  const { backgroundColor, foregroundColor } = currentPalette.parts[1]
  return colord(backgroundColor).delta(foregroundColor) !== 0
}

function sortForegroundByContrast(backgroundColors: string[], foregroundColors: string[]) {
  const averageContrasts = foregroundColors.map(fgColor => {
    const totalContrast = backgroundColors.reduce((acc, bgColor) => {
      return acc + colord(bgColor).contrast(fgColor)
    }, 0)
    const averageContrast = totalContrast / backgroundColors.length
    return { color: fgColor, averageContrast }
  })

  averageContrasts.sort((a, b) => b.averageContrast - a.averageContrast)

  return averageContrasts.map(item => item.color)
}
