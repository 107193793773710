import { ReactNode, createContext, useContext, useCallback } from 'react'
import { useIdentity } from '@vp/ubik-context'
import { APP_LOCALE, APP_TENANT, TEST_USER_ID } from '../constants/app'
import TrackingJS, { useTracking } from '@vp/react-tracking'
import {
  ButtonClickedProps,
  DownloadPageLogoDetailsProps,
  EmailLogoDataGeneratedProps,
  FlyOutClickedPros,
  FlyOutClosedEventProps,
  FlyOutViewedEventProps,
  getButtonClickedEvent,
  getDownloadPageLogoDetailsEvent,
  getEmailLogoDataGeneratedEvent,
  getFlyOutClickedEvent,
  getFlyOutClosedEvent,
  getFlyOutViewedEvent,
  getLogomakerCrosssellComponentEvent,
  getNavigationClickedEvent,
  getPageEvent,
  getProductViewedEvent,
  getTabSelectedEvent,
  LogomakerCrosssellComponentProps,
  NavigationClickedProps,
  PageContext,
  PageViewedProps,
  ProductViewedProps,
  TabSelectedProps,
} from '../utilities/events'
import ChannelTracking from '@vp/channel-tracking'
import { Tracking } from '@vp/tracking'
import { getCultureCode } from '../utilities/culture'
import { isClient } from '../utilities/isClient'

interface EventTracking {
  trackPage: (props?: PageViewedProps) => void
  trackDownloadPageLogoDetails: (props: DownloadPageLogoDetailsProps) => void
  trackTabSelected: (props: TabSelectedProps) => void
  trackNavigationClicked: (props: NavigationClickedProps) => void
  trackButtonClicked: (props: ButtonClickedProps) => void
  trackLogomakerCrosssellComponent: (props: LogomakerCrosssellComponentProps) => void
  trackFlyOutViewed: (props: FlyOutViewedEventProps) => void
  trackFlyOutClosed: (props: FlyOutClosedEventProps) => void
  trackFlyOutClicked: (props: FlyOutClickedPros) => void
  trackProductViewed: (props: ProductViewedProps) => void
  trackEmailLogoDataGenerated: (props: EmailLogoDataGeneratedProps) => void
}

export const EventTrackingContext = createContext<EventTracking | null>(null)

export const useEventTracking = (): EventTracking => {
  const context = useContext(EventTrackingContext)
  if (context === null) {
    throw new Error('useEventTracking must be used with EventTrackingContextProvider')
  }
  return context
}

interface TrackingContextProviderProps {
  children: ReactNode
  pageName: string
  isBrandShopRoute?: boolean
}

export const EventTrackingContextProvider = ({ children, pageName, isBrandShopRoute = false }: TrackingContextProviderProps) => {
  const auth = useIdentity()
  //@ts-ignore
  const canonicalId = auth.identity ? auth.identity.canonicalId : ''
  const locale = getCultureCode()
  const { tracking: segmentTracking } = useTracking()

  const pageContext: PageContext = isClient()
    ? {
        pageUrl: window.location.href,
        locale: locale,
        source: new URLSearchParams(window.location.search).get('source') || '',
        utmId: ChannelTracking.getTrackingData().utm_id || '',
        utmSource: ChannelTracking.getTrackingData().utm_source || '',
        pageName: pageName,
        isBrandShopRoute,
      }
    : {
        pageUrl: '',
        locale: locale,
        source: '',
        utmId: '',
        utmSource: '',
        pageName: pageName,
        isBrandShopRoute,
      }

  const trackingFunc = getTrackingFunction(canonicalId, segmentTracking as Tracking)

  const trackPage = useCallback((props: PageViewedProps = {}) => {
    const { pageName, payload } = getPageEvent(props, pageContext)
    trackingFunc.page(pageName, payload)
  }, [])

  const trackDownloadPageLogoDetails = useCallback((props: DownloadPageLogoDetailsProps) => {
    const { eventName, payload } = getDownloadPageLogoDetailsEvent(props, pageContext)
    trackingFunc.track(eventName, payload)
  }, [])

  const trackTabSelected = useCallback((props: TabSelectedProps) => {
    const { eventName, payload } = getTabSelectedEvent(props, pageContext)
    trackingFunc.track(eventName, payload)
  }, [])

  const trackNavigationClicked = useCallback((props: NavigationClickedProps) => {
    const { eventName, payload } = getNavigationClickedEvent(props, pageContext)
    trackingFunc.track(eventName, payload)
  }, [])

  const trackButtonClicked = useCallback((props: ButtonClickedProps) => {
    const { eventName, payload } = getButtonClickedEvent(props, pageContext)
    trackingFunc.track(eventName, payload)
  }, [])

  const trackLogomakerCrosssellComponent = useCallback((props: LogomakerCrosssellComponentProps) => {
    const { eventName, payload } = getLogomakerCrosssellComponentEvent(props, pageContext)
    trackingFunc.track(eventName, payload)
  }, [])

  const trackFlyOutViewed = useCallback((props: FlyOutViewedEventProps) => {
    const { eventName, payload } = getFlyOutViewedEvent(props, pageContext)
    trackingFunc.track(eventName, payload)
  }, [])

  const trackFlyOutClosed = useCallback((props: FlyOutClosedEventProps) => {
    const { eventName, payload } = getFlyOutClosedEvent(props, pageContext)
    trackingFunc.track(eventName, payload)
  }, [])

  const trackFlyOutClicked = useCallback((props: FlyOutClickedPros) => {
    const { eventName, payload } = getFlyOutClickedEvent(props, pageContext)
    trackingFunc.track(eventName, payload)
  }, [])

  const trackProductViewed = useCallback((props: ProductViewedProps) => {
    const { eventName, payload } = getProductViewedEvent(props, pageContext)
    trackingFunc.track(eventName, payload)
  }, [])

  const trackEmailLogoDataGenerated = useCallback((props: EmailLogoDataGeneratedProps) => {
    const { eventName, payload } = getEmailLogoDataGeneratedEvent(props, pageContext)
    trackingFunc.track(eventName, payload)
  }, [])

  return (
    <>
      <TrackingJS
        tenant={APP_TENANT}
        environment={process.env.UBIK_ENV || 'dev'}
        integrations={{ All: true }}
        culture={APP_LOCALE}
        suppressReloadOnConsentSubmit={false}
      />
      <EventTrackingContext.Provider
        value={{
          trackPage,
          trackEmailLogoDataGenerated,
          trackFlyOutViewed,
          trackFlyOutClosed,
          trackFlyOutClicked,
          trackDownloadPageLogoDetails,
          trackLogomakerCrosssellComponent,
          trackTabSelected,
          trackProductViewed,
          trackNavigationClicked,
          trackButtonClicked,
        }}
      >
        {children}
      </EventTrackingContext.Provider>
    </>
  )
}

export const getTrackingFunction = (canonicalId: string, segmentTracking: Tracking): Tracking => {
  // disable tracking for new relic synthetic monitor user
  return canonicalId === TEST_USER_ID ? inMemoryTracking : segmentTracking
}

const inMemoryTracking: Tracking = {
  visit: () => null,
  page: (pageName: string) => {
    console.debug('Tracking disabled, skipping tracking for page: ', pageName)
  },
  track: (eventName: string) => {
    console.debug('Tracking disabled, skipping event: ', eventName)
  },
  trackLink: () => {},
  trackNavigation: () => {},
  identify: () => {},
  reset: () => {},
  setAnonymousId: () => {},
  version: () => 'tracking-disabled',
}
