export type ImageWithDimensions = {
  url: string
} & ImageDimensions

export type ImageDimensions = {
  width: number
  height: number
}

export const getImageDimensions = (url: string): Promise<ImageDimensions> => {
  const img = new Image()
  img.src = url
  return new Promise(resolve => {
    img.crossOrigin = 'anonymous'
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width,
      })
    }

    img.onerror = () => {
      resolve({
        height: 4000,
        width: 4000,
      })
    }
  })
}
