export function getCookie(name: string) {
  try {
    const cookieArr = document.cookie.split(';')
    for (const cookie of cookieArr) {
      const cookiePair = cookie.split('=')
      if (name === cookiePair[0].trim()) {
        return decodeURIComponent(cookiePair[1])
      }
    }
    return null
  } catch (e) {
    return null
  }
}
