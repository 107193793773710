import { Box } from '@vp/swan'
import React, { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import PageNotFoundImg from '../../assets/404Page.webp'
import ErrorDisplay from './ErrorDisplay'
import { ErrorPageHeader } from './ErrorPageHeader'
import { logger } from '../../utilities/logger'
import classes, { stylesheet } from './ErrorPages.module.scss'
import { useStyles } from '@vp/ubik-context'

export type NotFoundRouteProps = {
  finalHomeURL?: string
}

const NotFoundPage: FC<NotFoundRouteProps> = ({ finalHomeURL }) => {
  useStyles(stylesheet)
  const { t } = useTranslation()

  useEffect(() => {
    const windowUrl = window.location.href
    const routesArr = windowUrl.split('/')
    if (window.location.href.includes('https://www.vistaprint.fr/logomaker/edit-logo')) {
      window.location.href = `https://www.vistaprint.fr/logo/creation-logo/edit-logo/${routesArr[routesArr.length - 1]}`
    } else if (window.location.href.includes('https://www.vistaprint.ca/logomaker/edit-logo')) {
      window.location.href = `https://www.vistaprint.ca/fr/logomaker/edit-logo/${routesArr[routesArr.length - 1]}`
    } else if (window.location.href.includes('https://www.vistaprint.ch/logomaker/edit-logo')) {
      window.location.href = `https://www.vistaprint.ch/fr/logomaker/edit-logo/${routesArr[routesArr.length - 1]}`
    } else {
      const err = new Error('Page Not Found')
      logger.error('Route Error: Error in finding the page to navigate', { error: err, homeUrl: finalHomeURL, windowUrl: windowUrl })
    }
    // eslint-x-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box className={classes.container}>
      <Helmet>
        <title>{t('404Error.title')}</title>
        {/*Added summary in wizard.route.tsx*/}
        <meta name="robots" content="noindex" />
      </Helmet>
      <ErrorPageHeader style={{ display: 'flex', justifyContent: 'center', paddingTop: '24px' }} />
      <ErrorDisplay
        heading={t('404Error.title')}
        description={t('404Error.description')}
        buttonText={t('404Error.buttonText')}
        image={PageNotFoundImg}
      />
    </Box>
  )
}

export default NotFoundPage
