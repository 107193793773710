import type { DimensionsInMm, Product, Surface } from '../../product'
import { minBy } from '../utils/arrayUtils'
import { ALL_DESIGN_CONCEPT_IDS } from './allDesignConceptIds'

import IMPORTED_DESIGNS from './cachedData/allDesignConcepts.json'
import { MANUAL_DESIGNS } from './cachedData/manualDesignConcepts'
import { CamConcept } from './camConcept'
import { CamImage } from './camImage'

const ALL_DESIGN_CONCEPTS: { [key: string]: CamConcept } = { ...MANUAL_DESIGNS, ...IMPORTED_DESIGNS }

// const designConceptIds = ['30842'];
// const designConceptIds = ['30842', '35711', '37473', '19672'];
// @note this is the abritrary order that dictates the order of the content in the gallery
const designConceptIds = [...ALL_DESIGN_CONCEPT_IDS.slice(0, 3), ...Object.keys(MANUAL_DESIGNS), ...ALL_DESIGN_CONCEPT_IDS.slice(3)]

export const fetchDesignConceptIds = async (authorizationHeader: string, requestor: string): Promise<string[]> => {
  return designConceptIds
}

export async function findFirstAccent(accents: CamImage[]): Promise<CamImage | undefined> {
  for (const item of accents) {
    return item
  }
  return undefined
}

export function wasteWhenCropping(image: DimensionsInMm, container: DimensionsInMm): number {
  const scale = Math.min(image.width / container.width, image.height / container.height)
  const waste = (image.width * image.height - scale * scale * container.width * container.height) / (image.width * image.height)
  return waste
}

export async function findBestBackground(backgrounds: CamImage[], surface: Surface): Promise<CamImage | undefined> {
  return minBy(backgrounds, background => wasteWhenCropping(background, surface))?.item
}

export const fetchDesignConceptDetails = async (
  authorizationHeader: string,
  designConceptId: string,
  product: Product,
  requestor: string,
): Promise<CamConcept> => {
  return ALL_DESIGN_CONCEPTS[designConceptId]
}
