// From: https://assets.algo.design.vpsvc.com/v1/color-variations?limit=1000

export type ColorIdentifier = 'color1' | 'color2' | 'color3' | 'dark' | 'light'

export interface IconColoring1 {
  colors: [ColorIdentifier]
  iconColorCount: 1
}

export interface IconColoring2 {
  colors: [ColorIdentifier, ColorIdentifier]
  iconColorCount: 2
}
export interface IconColoring3 {
  colors: [ColorIdentifier, ColorIdentifier, ColorIdentifier]
  iconColorCount: 3
}
export interface IconColoring4 {
  colors: [ColorIdentifier, ColorIdentifier, ColorIdentifier, ColorIdentifier]
  iconColorCount: 4
}

export type IconColoring = IconColoring1 | IconColoring2 | IconColoring3 | IconColoring4

export interface ColorPaletteVariation {
  id: string
  name: string
  variationNumber: number
  background: ColorIdentifier
  primaryText: ColorIdentifier
  secondaryText: ColorIdentifier
  icons: IconColoring[]
  version: number
}

export interface ColorPaletteVariationResponse {
  total: number
  limit: number
  offset: number
  result: ColorPaletteVariation[]
}

export function getColorPaletteVariations(): ColorPaletteVariationResponse {
  return data
}

const data: ColorPaletteVariationResponse = {
  total: 10,
  limit: 1000,
  offset: 1,
  result: [
    {
      id: '618ce8847c84191891e6f87b',
      name: 'COLOR 1 ALT BG',
      variationNumber: 4,
      background: 'color1',
      primaryText: 'dark',
      secondaryText: 'dark',
      icons: [
        {
          colors: ['dark'],
          iconColorCount: 1,
        },
        {
          colors: ['dark', 'color2'],
          iconColorCount: 2,
        },
      ],
      version: 0,
    },
    {
      id: '618ce7f77c84191891e6f86f',
      name: 'DARK BG',
      variationNumber: 2,
      background: 'dark',
      primaryText: 'light',
      secondaryText: 'color3',
      icons: [
        {
          colors: ['color1'],
          iconColorCount: 1,
        },
        {
          colors: ['color1', 'color2'],
          iconColorCount: 2,
        },
      ],
      version: 0,
    },
    {
      id: '618cea1d7c84191891e6f887',
      name: 'COLOR 3 BG',
      variationNumber: 7,
      background: 'color3',
      primaryText: 'light',
      secondaryText: 'light',
      icons: [
        {
          colors: ['light'],
          iconColorCount: 1,
        },
        {
          colors: ['light', 'color2'],
          iconColorCount: 2,
        },
      ],
      version: 0,
    },
    {
      id: '618ce75f7c84191891e6f863',
      name: 'LIGHT BG',
      variationNumber: 1,
      background: 'light',
      primaryText: 'dark',
      secondaryText: 'color1',
      icons: [
        {
          colors: ['color1'],
          iconColorCount: 1,
        },
        {
          colors: ['dark', 'color1'],
          iconColorCount: 2,
        },
        {
          colors: ['dark', 'color1', 'color2'],
          iconColorCount: 3,
        },
        {
          colors: ['dark', 'color1', 'color2', 'color3'],
          iconColorCount: 4,
        },
      ],
      version: 0,
    },
    {
      id: '622aee50bdae564df85d2349',
      name: 'DARK BG -2',
      variationNumber: 1000,
      background: 'dark',
      primaryText: 'light',
      secondaryText: 'color3',
      icons: [
        {
          colors: ['color1'],
          iconColorCount: 1,
        },
        {
          colors: ['color1', 'color2'],
          iconColorCount: 2,
        },
      ],
      version: 0,
    },
    {
      id: '618ce9ad7c84191891e6f87f',
      name: 'COLOR 2 BG',
      variationNumber: 5,
      background: 'color2',
      primaryText: 'light',
      secondaryText: 'light',
      icons: [
        {
          colors: ['light'],
          iconColorCount: 1,
        },
        {
          colors: ['light', 'color3'],
          iconColorCount: 2,
        },
      ],
      version: 0,
    },
    {
      id: '61ee47670ff430513b39f657',
      name: 'COLOR 3 BG - COPY',
      variationNumber: 9,
      background: 'color3',
      primaryText: 'light',
      secondaryText: 'light',
      icons: [
        {
          colors: ['light'],
          iconColorCount: 1,
        },
        {
          colors: ['light', 'color2'],
          iconColorCount: 2,
        },
      ],
      version: 0,
    },
    {
      id: '618ce8427c84191891e6f873',
      name: 'COLOR 1 BG',
      variationNumber: 3,
      background: 'color1',
      primaryText: 'light',
      secondaryText: 'light',
      icons: [
        {
          colors: ['light'],
          iconColorCount: 1,
        },
        {
          colors: ['color2', 'color3'],
          iconColorCount: 2,
        },
      ],
      version: 0,
    },
    {
      id: '618ce9e37c84191891e6f883',
      name: 'COLOR 2 ALT BG',
      variationNumber: 6,
      background: 'color2',
      primaryText: 'dark',
      secondaryText: 'dark',
      icons: [
        {
          colors: ['dark'],
          iconColorCount: 1,
        },
        {
          colors: ['dark', 'color1'],
          iconColorCount: 2,
        },
      ],
      version: 0,
    },
    {
      id: '618cea4e7c84191891e6f88d',
      name: 'COLOR 3 ALT BG',
      variationNumber: 8,
      background: 'color3',
      primaryText: 'dark',
      secondaryText: 'dark',
      icons: [
        {
          colors: ['dark'],
          iconColorCount: 1,
        },
        {
          colors: ['dark', 'color2'],
          iconColorCount: 2,
        },
      ],
      version: 0,
    },
  ],
}
