import type { FontSet, FontSetFont } from './types'
import { createUniqueId } from './createUniqueId'

const fonts: [FontSetFont, FontSetFont] = [
  {
    casing: 'default',
    familyName: 'Raleway Medium',
    leading: 1.1,
    relativeSize: 100,
    tracking: 3,
    style: 'normal',
  },
  {
    casing: 'uppercase',
    familyName: 'Raleway',
    leading: 1.1,
    relativeSize: 100,
    tracking: 1.5,
    style: 'normal',
  },
]

export const defaultFontSet: FontSet = {
  id: createUniqueId(fonts),
  fonts: [...fonts, ...fonts],
}
