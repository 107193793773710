import { useUserContext } from '@vp/ubik-context'
import { getCultureCode } from '../utilities/culture'

export const APP_LOCALE = 'en-US';
export const APP_TENANT = process.env.UBIK_TRACKING_TENANT || 'vistaprint'
export const WEBSITE_DOMAINS_LOCALES = [
  'en-US',
  'en-GB',
  'en-CA',
  'en-AU',
  'en-NZ',
  'en-SG',
  'en-IE',
  'en-IN',
  'fr-FR',
  'fr-CA',
  'de-DE',
  'fr-CH',
  'de-AT',
  'de-CH',
]
export const DOMAIN_OFFERING_PARTNER_NAME = 'WIX'
export const TEST_USER_ID = 'd2FyaG9s-8593DF9C6AE9DBE704D7A774837FE532'
export const BUSINESS_CARD_MPV_ID = 'standardBusinessCards'
export const SURVEY_FEEDBACK_LOCALES = ['en-US', 'en-GB', 'en-AU', 'en-CA', 'en-NZ', 'en-SG', 'en-IE', 'en-IN']
