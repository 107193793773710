import { PipeSeparator, Typography } from '@vp/swan'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { VpLogoColoredSmall } from '../../constants/icons'
import classes from '../VistaLogoHeader/VistaLogoHeader.module.scss'
import { stylesheet } from './ErrorPages.module.scss'
import { useStyles } from '@vp/ubik-context'

type ErrorPageHeaderProp = {
  style?: React.CSSProperties
}

export const ErrorPageHeader: FC<ErrorPageHeaderProp> = ({ style }) => {
  useStyles(stylesheet)
  const { t } = useTranslation()
  return (
    <div className={classes.errorPageHeaderContainer} style={style}>
      <VpLogoColoredSmall />
      <PipeSeparator style={{ color: '#767676' }} />
      <Typography fontSize="standard" textAlign="left" fontWeight="bold">
        <Typography fontWeight="bold">
          {t('wizard.wizardHeaderSplitText')}{' '}
          <Typography as="span" fontWeight="normal">
            {t('wizard.wizardHeaderSplitText1')}
          </Typography>{' '}
          {t('wizard.wizardHeaderSplitText2')}
          {''}
          <Typography as="span" fontWeight="normal">
            ®
          </Typography>{' '}
        </Typography>
      </Typography>
    </div>
  )
}
