import React from 'react'
import CustomSceneImage from './CustomSceneImage'
import { GenerateDesignProduct } from '../../../api/smartDesignEngine'
import productData from '../shareble-page-products.json'
import { Box } from '@vp/swan'
import classes, {stylesheet} from './ProductShowCase.module.scss'
import { ImageWithDimensions } from '../../../utilities/image'
import { useStyles } from '@vp/ubik-context'

interface ProductShowCaseProps {
  logo: ImageWithDimensions
  firstProductMpvId: string
  secondProductMpvId: string
  backgroundColor: string
}

type ProductData = {
  [key: string]: {
    productDetails: GenerateDesignProduct
    sceneId: string
    productName: string
  }
}

const typedProductData = productData as ProductData

const getProduct = (productKey: string) => {
  return typedProductData[productKey] || null
}

const ProductShowCase: React.FC<ProductShowCaseProps> = ({ logo, firstProductMpvId, secondProductMpvId, backgroundColor }) => {
  useStyles(stylesheet)
  const firstProduct = getProduct(firstProductMpvId)
  const secondProduct = getProduct(secondProductMpvId)

  return (
    <Box className={classes.showCaseWrapper}>
      {firstProduct && (
        <Box>
          <CustomSceneImage
            productDetails={firstProduct.productDetails}
            sceneId={firstProduct.sceneId}
            backgroundColor={backgroundColor}
            logo={logo}
            width="2000"
            productName={firstProduct.productName}
          />
        </Box>
      )}
      {secondProduct && (
        <Box>
          <CustomSceneImage
            productDetails={secondProduct.productDetails}
            sceneId={secondProduct.sceneId}
            backgroundColor={backgroundColor}
            logo={logo}
            width="2000"
            productName={secondProduct.productName}
          />
        </Box>
      )}
    </Box>
  )
}

export default React.memo(ProductShowCase)
