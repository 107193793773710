import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import enGB from './en-gb.json'
import enUS from './en-us.json'
import frFR from './fr-fr.json'
import frCA from './fr-ca.json'
import deDE from './de-de.json'
import daDK from './da-dk.json'
import esES from './es-es.json'
import itIT from './it-it.json'
import svSE from './sv-se.json'
import nlNL from './nl-nl.json'
import ptPT from './pt-pt.json'
import fiFi from './fi-fi.json'
import esUs from './es-us.json'
import nbNo from './nb-no.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translations: enUS,
      },
      'en-GB': {
        translations: enGB,
      },
      'en-AU': {
        translations: enGB,
      },
      'en-NZ': {
        translations: enGB,
      },
      'en-SG': {
        translations: enGB,
      },
      'en-IE': {
        translations: enGB,
      },
      'en-US': {
        translations: enUS,
      },
      'en-CA': {
        translations: enUS,
      },
      'en-IN': {
        translations: enGB,
      },
      'fr-FR': {
        translations: frFR,
      },
      'fr-CA': {
        translations: frCA,
      },
      'fr-CH': {
        translations: frFR,
      },
      'de-DE': {
        translations: deDE,
      },
      'de-AT': {
        translations: deDE,
      },
      'de-CH': {
        translations: deDE,
      },
      'it-IT': {
        translations: itIT,
      },
      'es-ES': {
        translations: esES,
      },
      'da-DK': {
        translations: daDK,
      },
      'sv-SE': {
        translations: svSE,
      },
      'nl-BE': {
        translations: nlNL,
      },
      'fr-BE': {
        translations: frFR,
      },
      'it-CH': {
        translations: itIT,
      },
      'pt-PT': {
        translations: ptPT,
      },
      'fi-FI': {
        translations: fiFi,
      },
      'es-US': {
        translations: esUs,
      },
      'nb-NO': {
        translations: nbNo,
      },
      'nl-NL': {
        translations: nlNL,
      },
    },
    fallbackLng: 'en',
    // debug: window.location.hostname === 'localhost' || process.env.UBIK_ENV === 'dev',
    debug: true,
    ns: ['translations'],
    defaultNS: 'translations',
  })

export default i18n
