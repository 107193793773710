import { CamConcept } from '../../camConcept'

export const MANUAL_DESIGNS: { [key: string]: CamConcept } = {
  manual1: {
    accents: [
      {
        height: 66.675,
        width: 58,
        type: 'vector',
        colors: ['rgb(#000000)'],
        url: 'https://api.sherbert.cimpress.io/v3/assets/yz4VCAKttxTjM_KRqVa8H~100?signature=c68373a7811c18ea4db068df39237709d39e9093',
      },
    ],
    backgrounds: [
      {
        height: 67,
        width: 66.675,
        type: 'vector',
        colors: ['rgb(#e6d4cc)', 'rgb(#e5c9bd)'],
        url: 'https://api.sherbert.cimpress.io/v3/assets/3-VMc4alDdqReHTi0mF1B~100?signature=7f2bc3c4fda6c55cf5c31ef9fb4566ef3a1c804d',
      },
    ],
    colorPalettes: [
      {
        type: 'extended',
        id: 'red',
        name: 'Red',
        parts: [
          {
            foregroundColors: ['cmyk(60,40,40,100)'],
            backgroundColors: ['cmyk(0,86,98,0)', 'cmyk(0,92,94,6)'],
          },
        ],
      },
      {
        type: 'extended',
        id: 'green',
        name: 'Green',
        parts: [
          {
            foregroundColors: ['cmyk(60,40,40,100)'],
            backgroundColors: ['cmyk(0,15,15,10)', 'cmyk(0,5,5,10)'],
          },
        ],
      },
      {
        type: 'extended',
        id: 'orange',
        name: 'Orange',
        parts: [
          {
            foregroundColors: ['cmyk(90,50,20,76)'],
            backgroundColors: ['cmyk(0,50,100,0)', 'cmyk(0,70,100,0)'],
          },
        ],
      },
      {
        type: 'extended',
        id: 'red2',
        name: 'Red 2',
        parts: [
          {
            foregroundColors: ['cmyk(0,0,0,0)'],
            backgroundColors: ['cmyk(0,92,94,6)', 'cmyk(0,86,98,0)'],
          },
        ],
      },
      {
        type: 'extended',
        id: 'yellow',
        name: 'Yellow',
        parts: [
          {
            foregroundColors: ['cmyk(90,50,20,74)'],
            backgroundColors: ['cmyk(0,20,82,0)', 'cmyk(0,30,82,0)'],
          },
        ],
      },
    ],
    fontSchemas: [
      {
        id: 'barlow_scheme',
        fonts: [
          {
            familyName: 'Barlow Semi Condensed SemiBold',
            style: 'Normal',
            casing: 'Uppercase',
            leading: 1.2,
            tracking: -1,
            relativeSize: 100,
          },
          {
            familyName: 'Barlow ExtraBold',
            style: 'Normal',
            casing: 'Uppercase',
            leading: 1.2,
            tracking: 10,
            relativeSize: 75 * 1.25,
          },
        ],
      },
    ],
    id: 'manual1',
  },
  manual2: {
    accents: [
      {
        width: 847,
        height: 900,
        previewUrl: 'https://uploads.documents.cimpress.io/v1/uploads/8b0fcf70-b7db-4744-b054-4795caeb19a2~110?tenant=vbu-cl',
        printUrl: 'https://uploads.documents.cimpress.io/v1/uploads/67a0b2d6-565b-4845-9c64-628ff50f4e5f~110/print?tenant=vbu-cl',
        recolorable: true,
      },
    ],
    backgrounds: [
      {
        width: 1167,
        height: 792,
        previewUrl: 'https://uploads.documents.cimpress.io/v1/uploads/c8d431a2-39fc-47c3-bd1f-b42c3d791abf~110/preview?tenant=vbu-cl',
        printUrl: 'https://uploads.documents.cimpress.io/v1/uploads/c8d431a2-39fc-47c3-bd1f-b42c3d791abf~110/print?tenant=vbu-cl',
        recolorable: true,
      },
    ],
    colorPalettes: [
      {
        type: 'extended',
        id: 'tan',
        name: 'Tan',
        parts: [
          {
            foregroundColors: ['cmyk(0,0,0,78)'],
            backgroundColors: ['cmyk(5,26,35,20)'],
          },
        ],
      },
      {
        type: 'extended',
        id: 'green',
        name: 'Green',
        parts: [
          {
            foregroundColors: ['cmyk(0,0,0,78)'],
            backgroundColors: ['cmyk(40,25,40,10)'],
          },
        ],
      },
      {
        type: 'extended',
        id: 'purple',
        name: 'Purple',
        parts: [
          {
            foregroundColors: ['cmyk(0,0,0,78)'],
            backgroundColors: ['cmyk(40,50,20,0)'],
          },
        ],
      },
      {
        type: 'extended',
        id: 'blue',
        name: 'Blue',
        parts: [
          {
            foregroundColors: ['cmyk(0,0,0,78)'],
            backgroundColors: ['cmyk(50,10,5,10)'],
          },
        ],
      },
      {
        type: 'extended',
        id: 'pink',
        name: 'Pink',
        parts: [
          {
            foregroundColors: ['cmyk(0,0,0,78)'],
            backgroundColors: ['cmyk(0,45,10,20)'],
          },
        ],
      },
      {
        type: 'extended',
        id: 'gray',
        name: 'Gray',
        parts: [
          {
            foregroundColors: ['cmyk(0,0,0,78)'],
            backgroundColors: ['cmyk(20,15,10,20)'],
          },
        ],
      },
    ],
    fontSchemas: [
      {
        id: 'barlow_scheme',
        fonts: [
          {
            familyName: 'Kumbh Sans',
            style: 'Normal',
            casing: 'Uppercase',
            leading: 1.2,
            tracking: 20,
            relativeSize: 90,
          },
          {
            familyName: 'Kumbh Sans',
            style: 'Normal',
            casing: 'Uppercase',
            leading: 1.2,
            tracking: 20,
            relativeSize: 65 * 1.25,
          },
        ],
      },
    ],
    id: 'manual2',
  },
  manual3: {
    accents: [
      {
        height: 56.4265,
        width: 66.675,
        type: 'vector',
        colors: ['rgb(#000000)', 'rgb(#fac5c4)'],
        url: 'https://api.sherbert.cimpress.io/v3/assets/BbEQxqrpAFMuTeADI9vtN~100?signature=d6818be9129e41547e58cb0940bd5525fb373010',
      },
    ],
    backgrounds: [
      {
        height: 39,
        width: 66.675,
        type: 'vector',
        colors: ['rgb(#a1472d)', 'rgb(#913f26)'],
        url: 'https://api.sherbert.cimpress.io/v3/assets/MHLkujfhO44gfaHCPgZa4~100?signature=b302e683c2d003fd8b131eff8dc081b458915acf',
      },
    ],
    colorPalettes: [
      {
        type: 'extended',
        name: '1',
        id: '1',
        parts: [
          {
            foregroundColors: ['cmyk(0,27,14,0)', 'cmyk(0,27,14,0)'],
            backgroundColors: ['cmyk(25,80,90,20)', 'cmyk(25,80,90,30)'],
          },
          {
            foregroundColors: ['cmyk(25,80,90,20)', 'cmyk(25,80,90,30)'],
            backgroundColors: ['cmyk(0,27,14,0)', 'cmyk(0,27,14,0)'],
          },
        ],
      },
      {
        type: 'extended',
        name: '2',
        id: '2',
        parts: [
          {
            foregroundColors: ['cmyk(0,5,15,5)', 'cmyk(25,30,70,10)'],
            backgroundColors: ['cmyk(65,80,55,45)', 'cmyk(65,80,55,57)'],
          },
          {
            foregroundColors: ['cmyk(65,80,55,45)', 'cmyk(65,80,55,57)'],
            backgroundColors: ['cmyk(25,30,70,10)', 'cmyk(0,5,15,5)'],
          },
        ],
      },
      {
        type: 'extended',
        name: '3',
        id: '3',
        parts: [
          {
            foregroundColors: ['cmyk(4,22,50,0)', 'cmyk(4,22,50,0)'],
            backgroundColors: ['cmyk(60,40,40,85)', 'cmyk(60,40,40,100)'],
          },
          {
            foregroundColors: ['cmyk(60,40,40,85)', 'cmyk(60,40,40,100)'],
            backgroundColors: ['cmyk(4,22,50,0)', 'cmyk(4,22,50,0)'],
          },
        ],
      },
      {
        type: 'extended',
        name: '4',
        id: '4',
        parts: [
          {
            foregroundColors: ['cmyk(0,0,3,3)', 'cmyk(0,0,3,3)'],
            backgroundColors: ['cmyk(25,15,32,25)', 'cmyk(25,15,32,33)'],
          },
          {
            foregroundColors: ['cmyk(25,15,32,25)', 'cmyk(25,15,32,33)'],
            backgroundColors: ['cmyk(0,0,3,3)', 'cmyk(0,0,3,3)'],
          },
        ],
      },
      {
        type: 'extended',
        name: '5',
        id: '5',
        parts: [
          {
            foregroundColors: ['cmyk(45,55,50,80)', 'cmyk(45,55,50,80)'],
            backgroundColors: ['cmyk(2,5,8,4)', 'cmyk(3,6,10,10)'],
          },
          {
            foregroundColors: ['cmyk(2,5,8,4)', 'cmyk(3,6,10,10)'],
            backgroundColors: ['cmyk(45,55,50,80)', 'cmyk(45,55,50,80)'],
          },
        ],
      },
    ],
    fontSchemas: [
      {
        id: 'barlow_scheme',
        fonts: [
          {
            familyName: 'Italiana',
            style: 'Normal',
            casing: 'Lowercase',
            leading: 1.2,
            tracking: 0,
            relativeSize: 110,
          },
          {
            familyName: 'Poppins Light',
            style: 'Normal',
            casing: 'Uppercase',
            leading: 1.2,
            tracking: 10,
            relativeSize: 70 * 1.25,
          },
        ],
      },
    ],
    id: 'manual3',
  },
  manual4: {
    accents: [
      {
        height: 66.675,
        width: 61.97,
        type: 'vector',
        colors: ['_', 'rgb(#ec4b66)'],
        backgroundColors: ['_', '_', 'rgb(#ffffff)'],
        url: 'https://api.sherbert.cimpress.io/v3/assets/462GqzJKGuJRGeDkU9F4P~100?signature=1377093e8f2429fa85f55373300f722138ee4330',
      },
    ],
    backgrounds: [
      {
        height: 42.164,
        width: 66.675,
        type: 'vector',
        colors: ['rgb(#ddddde)', 'rgb(#eceded)'],
        url: 'https://api.sherbert.cimpress.io/v3/assets/YGOqs4zXehCsXzb8OLPS0~100?signature=82695d967e98c74aa3bdf09dc142fcc85811527d',
      },
    ],
    colorPalettes: [
      {
        type: 'extended',
        id: '1',
        name: '1',
        parts: [
          {
            backgroundColors: ['cmyk(6,4,4,0)', 'cmyk(12,9,9,0)', 'cmyk(0,0,0,0)'],
            foregroundColors: ['cmyk(60,40,40,100)', 'cmyk(1,86,47,0)'],
          },
        ],
      },
      {
        type: 'extended',
        id: '2',
        name: '2',
        parts: [
          {
            backgroundColors: ['cmyk(21,100,88,12)', 'cmyk(25,100,88,22)', 'cmyk(19,99,82,9)'],
            foregroundColors: ['cmyk(0,0,0,0)', 'cmyk(6,35,100,0)'],
          },
        ],
      },
      {
        type: 'extended',
        id: '3',
        name: '3',
        parts: [
          {
            backgroundColors: ['cmyk(100,83,36,27)', 'cmyk(100,81,47,51)', 'cmyk(100,74,30,14)'],
            foregroundColors: ['cmyk(0,0,0,0)', 'cmyk(8,44,100,0)'],
          },
        ],
      },
      {
        type: 'extended',
        id: '4',
        name: '4',
        parts: [
          {
            backgroundColors: ['cmyk(90,78,51,60)', 'cmyk(89,77,55,70)', 'cmyk(91,76,48,49)'],
            foregroundColors: ['cmyk(0,0,0,0)', 'cmyk(21,100,88,12)'],
          },
        ],
      },
    ],
    fontSchemas: [
      {
        id: 'barlow_scheme',
        fonts: [
          {
            familyName: 'Ikra Slab',
            style: 'Normal',
            casing: 'Uppercase',
            leading: 1.2,
            tracking: 39,
            relativeSize: 70,
          },
          {
            familyName: 'Ikra Slab',
            style: 'Normal',
            casing: 'Lowercase',
            leading: 1.2,
            tracking: 26,
            relativeSize: 55 * 1.25,
          },
        ],
      },
    ],
    id: 'manual4',
  },
  manual5: {
    accents: [
      {
        height: 62,
        width: 67,
        type: 'vector',
        colors: ['rgb(#fdf499)'],
        backgroundColors: ['rgb(#cc7c7b)'],
        url: 'https://api.sherbert.cimpress.io/v3/assets/7RGt9Z_2m3mbK2M9vLOXt~100?signature=c7409864a9818f46e295775212f1ca41694095fe',
      },
    ],
    backgrounds: [
      {
        height: 44.54,
        width: 67,
        type: 'vector',
        colors: ['rgb(#d18687)', 'rgb(#cc7c7b)'],
        url: 'https://api.sherbert.cimpress.io/v3/assets/t2RgsRCQAS5_vAHcdCfkv~100?signature=b038bbd2efce89e9548960b0b36757f028d801e5',
      },
    ],
    colorPalettes: [
      {
        id: '1',
        name: '1',
        type: 'extended',
        parts: [
          {
            foregroundColors: ['cmyk(2,0,50,0)'],
            backgroundColors: ['cmyk(17,55,38,0)', 'cmyk(18,60,43,1)'],
          },
          {
            foregroundColors: ['cmyk(2,0,50,0)'],
            backgroundColors: ['cmyk(96,66,46,33)'],
          },
        ],
      },
      {
        id: '2',
        name: '2',
        type: 'extended',
        parts: [
          {
            foregroundColors: ['cmyk(2,0,49,0)'],
            backgroundColors: ['cmyk(96,66,46,33)', 'cmyk(95,66,49,40)'],
          },
          {
            foregroundColors: ['cmyk(2,0,49,0)'],
            backgroundColors: ['cmyk(15,66,39,0)'],
          },
        ],
      },
      {
        id: '3',
        name: '3',
        type: 'extended',
        parts: [
          {
            foregroundColors: ['cmyk(2,0,49,0)'],
            backgroundColors: ['cmyk(37,78,76,43)', 'cmyk(40,78,75,50)'],
          },
          {
            foregroundColors: ['cmyk(2,0,49,0)'],
            backgroundColors: ['cmyk(49,40,100,17)'],
          },
        ],
      },
      {
        id: '4',
        name: '4',
        type: 'extended',
        parts: [
          {
            foregroundColors: ['cmyk(2,0,49,0)'],
            backgroundColors: ['cmyk(86,100,27,16)', 'cmyk(90,100,30,21)'],
          },
          {
            foregroundColors: ['cmyk(2,0,49,0)'],
            backgroundColors: ['cmyk(37,78,75,43)'],
          },
        ],
      },
      {
        id: '5',
        name: '5',
        type: 'extended',
        parts: [
          {
            foregroundColors: ['cmyk(2,0,49,0)'],
            backgroundColors: ['cmyk(49,40,100,17)', 'cmyk(51,43,100,22)'],
          },
          {
            foregroundColors: ['cmyk(2,0,49,0)'],
            backgroundColors: ['cmyk(90,100,30,21)'],
          },
        ],
      },
    ],
    fontSchemas: [
      {
        id: 'barlow_scheme',
        fonts: [
          {
            familyName: 'Mostra Nuova',
            style: 'Normal',
            casing: 'Uppercase',
            leading: 1.2,
            tracking: 12,
            relativeSize: 100,
          },
          {
            familyName: 'Mostra Nuova',
            style: 'Normal',
            casing: 'Lowercase',
            leading: 1.2,
            tracking: 20,
            relativeSize: 80 * 1.25,
          },
        ],
      },
    ],
    id: 'manual5',
  },
  manual6: {
    accents: [
      {
        height: 66.675,
        width: 59,
        type: 'vector',
        colors: ['rgb(#00b3f0)', 'rgb(#ff0000)', 'rgb(#00ff00)'],
        backgroundColors: ['rgb(#ec008c)'],
        url: 'https://api.sherbert.cimpress.io/v3/assets/yRhJSrMfUzRPNuaVs4kPD~100?signature=d6e43dea68f0f4b00c4026a15223872614ad3ca2',
      },
    ],
    backgrounds: [],
    colorPalettes: [
      // TODO: Backside/complementary palettes
      // Also, something is wrong with my indd file and there may be more colors than this
      {
        type: 'extended',
        id: '1',
        name: '1',
        parts: [
          {
            foregroundColors: ['cmyk(69,84,34,40)', 'cmyk(0,3,25,0)', 'cmyk(0,40,29,0)'],
            backgroundColors: ['cmyk(0,21,12,0)'],
          },
        ],
      },
      {
        type: 'extended',
        id: '2',
        name: '2',
        parts: [
          {
            foregroundColors: ['cmyk(69,84,34,40)', 'cmyk(3,0,21,0)', 'cmyk(0,34,25,0)'],
            backgroundColors: ['cmyk(8,16,0,0)'],
          },
        ],
      },
      {
        type: 'extended',
        id: '3',
        name: '3',
        parts: [
          {
            foregroundColors: ['cmyk(50,0,20,85)', 'cmyk(5,0,14,0)', 'cmyk(0,34,25,0)'],
            backgroundColors: ['cmyk(25,0,17,0)'],
          },
        ],
      },
      {
        type: 'extended',
        id: '4',
        name: '4',
        parts: [
          {
            foregroundColors: ['cmyk(82,96,96,20)', 'cmyk(0,0,10,0)', 'cmyk(0,40,29,0)'],
            backgroundColors: ['cmyk(0,10,50,0)'],
          },
        ],
      },
      {
        type: 'extended',
        id: '5',
        name: '5',
        parts: [
          {
            foregroundColors: ['cmyk(69,84,34,30)', 'cmyk(0,15,3,0)', 'cmyk(0,45,15,0)'],
            backgroundColors: ['cmyk(0,0,0,7)'],
          },
        ],
      },
    ],
    fontSchemas: [
      {
        id: 'barlow_scheme',
        fonts: [
          {
            familyName: 'Josefin Sans',
            style: 'Normal',
            casing: 'Uppercase',
            leading: 1.2,
            tracking: 8,
            relativeSize: 95,
          },
          {
            familyName: 'Josefin Sans Light',
            style: 'Normal',
            casing: 'Uppercase',
            leading: 1.2,
            tracking: 10,
            relativeSize: 75 * 1.25,
          },
        ],
      },
    ],
    id: 'manual6',
  },
  manual7: {
    accents: [
      {
        height: 66.675,
        width: 67,
        type: 'vector',
        colors: ['rgb(#d8c5b3)'],
        backgroundColors: ['rgb(#000000)'],
        url: 'https://api.sherbert.cimpress.io/v3/assets/jwziwpglbBQh50PcFgLFx~100?signature=b0235b671679c1d11cda1217dcf250d8d81cf2a9',
      },
    ],
    backgrounds: [
      {
        height: 67,
        width: 66.675,
        type: 'vector',
        colors: ['rgb(#000000)', 'rgb(#4f3c2f)'],
        url: 'https://api.sherbert.cimpress.io/v3/assets/1i4zN_CfGoEurpIs2-Gm9~100?signature=24f9a4f2d32860c79544554e67f3f0930223ef4e',
      },
    ],
    colorPalettes: [
      {
        type: 'extended',
        id: '1',
        name: '1',
        parts: [
          {
            foregroundColors: ['cmyk(15,20,28,0)'],
            backgroundColors: ['cmyk(60,40,40,100)', 'cmyk(52,63,72,52)'],
          },
        ],
      },
      {
        type: 'extended',
        id: '2',
        name: '2',
        parts: [
          {
            foregroundColors: ['cmyk(34,36,72,5)'],
            backgroundColors: ['cmyk(63,71,69,84)', 'cmyk(52,63,72,52)'],
          },
        ],
      },
      {
        type: 'extended',
        id: '3',
        name: '3',
        parts: [
          {
            foregroundColors: ['cmyk(19,45,73,1)'],
            backgroundColors: ['cmyk(77,59,72,82)', 'cmyk(52,63,72,52)'],
          },
        ],
      },
      {
        type: 'extended',
        id: '4',
        name: '4',
        parts: [
          {
            foregroundColors: ['cmyk(68,69,67,85)'],
            backgroundColors: ['cmyk(33,40,71,6)', 'cmyk(40,53,82,22)'],
          },
        ],
      },
    ],
    fontSchemas: [
      {
        id: 'barlow_scheme',
        fonts: [
          {
            familyName: 'Proxima Nova Black',
            style: 'Normal',
            casing: 'Uppercase',
            leading: 1.2,
            tracking: 10,
            relativeSize: 100,
          },
          {
            familyName: 'Open Sans',
            style: 'Normal',
            casing: 'Lowercase',
            leading: 1.2,
            tracking: 20,
            relativeSize: 70 * 1.25,
          },
        ],
      },
    ],
    id: 'manual7',
  },
}
