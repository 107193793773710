import { ProductsAndTargetSpecificationsResponse } from './types'

export async function getTargetSpecification(
  prd: string,
  productVersion: number,
  optionSelections: Record<string, string>,
  culture: string,
): Promise<ProductsAndTargetSpecificationsResponse> {
  const optionsQueryString = Object.keys(optionSelections).reduce(
    (prev, key) => `${prev}${encodeURIComponent(`optionSelections[${key}]`)}=${encodeURIComponent(optionSelections[key])}&`,
    '',
  )
  const response = await fetch(
    `https://targetspecifications.artworkgeneration.cimpress.io/api/v5/productSpecifications/${prd}/version/${productVersion}?${optionsQueryString}culture=${culture}`,
  )
  const json = await response.json()
  return json as ProductsAndTargetSpecificationsResponse
}
