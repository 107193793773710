export const logger = {
  error: (message: string | Error, ...optionalParams: any[]): void => {
    const filteredParams = optionalParams.filter(Boolean)

    console.error(message, ...filteredParams)

    if (typeof window.rollbar?.error === 'function') {
      window.rollbar.error(message, ...filteredParams)
    }

    if (typeof window.newrelic?.noticeError === 'function') {
      window.newrelic.noticeError(message, ...filteredParams)
    }
  },
}
